@import "./../../Theme/theme";

.container {
  max-width: 100%;
  #control-hooks{
    div{
      margin-bottom: 16px !important;
    }
  }
  .input {
    height: 44px;
    div{
      height: 44px !important;
      margin-bottom: 16px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
.input {
  width: 583px;
  height: 44px;
  color: rgba(0, 0, 0, 0.85);
}
.dropdown{
  -webkit-overflow-scrolling: 'touch'
}
.dropdown::-webkit-scrollbar {
  width: 8px;               /* ширина scrollbar */
}
.dropdown::-webkit-scrollbar-track {
  background: none;        /* цвет дорожки */
}
.dropdown::-webkit-scrollbar-thumb {
  background-color: #7a7a7a;    /* цвет плашки */
  border-radius: 4px;       /* закругления плашки */
  //border: 3px solid orange;  /* padding вокруг плашки */
}
.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
// .margin50 {
//   margin-top: 50px;
// }

.withoutMargin {
  margin-bottom: 0;
}

.link {
  display: block;
  margin-top: -8px;
  margin-bottom: 16px;
  //@include font($color: $primary-color)
  @include font(14px, #5B8DB4, 400, 18px);

}

.cantFind{
  @include font(16px,#026BB5, 700, 22px);
  margin-top: 16px;
  text-align: center;
  text-decoration-line: underline;
  a{
    text-decoration: none !important;
    color: #026BB5;
  }
}

.tabbyPromo{
  display: flex;
  justify-content: center;
  margin-top: 16px;
  p{
    margin: auto 0;
    margin-right: 5px;
    @include font(16px, rgba(0, 0, 0, 0.85), 700, 24px);
  }
  img{
    width: 78px;
    height: 32px  ;
  }
}

@media screen and (max-width: 428px) {
  .link {
    padding-top: 2px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px; 
  }
  .tabbyPromo{
    p{
      font-size: 14px;
    }
  }
}