@import '../../../../Theme/theme';

.contentContainer {
  display: flex;
  justify-content: center;
  gap: 109px;
  flex-wrap: wrap;
  margin-left: 101px;

  .leftContainerWrapper {
    margin-top: 40px;
    max-width: 570px;

    .subtitle {
      @include font($color: $font-color);
    }
  }

  .checkBoxText {
    @include font($size: 12px, $color: $font-color);
    max-width: 265px;
    margin-bottom: 24px;
    align-items: center;
  }
}
.buttons {
  width: 95%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}

@media (max-width: 1310px) {
  .contentContainer {
    margin-left: 0px;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    .leftContainerWrapper {
      margin-top: 0;
    }
  }
  .hide {
    display: none;
  }
}

@media (max-width: 835px) {
  .container {
    flex-direction: column;
  }

  .contentContainer {
    flex-wrap: wrap;
    .leftContainerWrapper {
      margin-top: 0;
    }
  }
}
@media (max-width: 575px) {
  .contentContainer {
    .leftContainerWrapper {
      margin: 0 16px;
    }
  }
}
@media (max-width: 410px) {
  .contentContainer {
    .leftContainerWrapper {
      max-width: 340px;
    }
  }
}
