@import '../../Theme/theme';

.carImage{
  background: url('../../Assets/carBackground.svg') no-repeat;
  background-size: cover;
  width: 402px;
  height: 296px;
  margin-top: 70px;
  img {
    margin-left: 40px;
    max-width: 260px;
  }
}

.headInfo {
  min-width: 200px;
  min-height: 80px;
  margin-left: 40px;
  margin-bottom: 39px;
  h4 {
    @include font(16px, $white, $fw-bold, 24px);
  }
  b {
    @include font(14px, $white, $fw-bold, 22px);
    margin-left: 16px;
  }
}

.rightBlockInfo {
  margin-left: 46.5px;
  max-width: 310px;
}

.summari {
  @include fontMulishBold(20px, $black-color, 800, 120%);
}

.summariHeader{
  .workshopName {
    //margin-top: 10px; 
    @include font(14px, $black-color, $fw-bold, 22px);
    margin-bottom: 2px;
  }
  
  .itemWrapperDate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //margin-top: 9px;
    margin-left: 24px;
  
    .title {
      @include font(14px, $devider-grey, $fw-regular, 22px);
    }
  
    .time {
      //min-width: 65px;
      @include font(14px, $header-color, $fw-regular, 22px);
    }
    .price {
      @include font(16px, $header-color, $fw-bold, 24px);
    }
  }
}

.priceBreakdown{
  margin-top: 16px;
  margin-bottom: 8px;
  span{
    @include fontMulishBold(16px, #026BB5, $fw-bold, 120%);
  }
  p{
    @include font(14px, rgba(0, 0, 0, 0.45), 400, 22px);
    margin: 0;
  }
}

.services{
  margin-bottom: 8px;
  .devider {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    //margin-top: 16px;
    //margin-bottom: 16px;
    .priceBold{
      @include fontMulishBold(14px, $black-color, 800, 120%);
    }
  }
  .servicesTitle {
    @include fontMulishBold(14px, $header-color, $fw-bold, 120%);
    cursor: pointer;
    //margin-bottom: 6px;
  }
  
  .servicesTitleMajor {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    @include fontMulishBold(14px, $header-color, $fw-bold, 120%);
    cursor: pointer;
    //margin-bottom: 6px;
  }
  .chieldPrices{
    display: flex;
    justify-content: space-between;
    width: 286px;
    margin-left: 24px;
    //margin-bottom: 8px;
    @include font(14px, $devider-grey, 500, 22px);
    .mainServiceTitle{
      max-width: 220px;
    }
    .freePrice{
      display: flex;
      align-items: center;
      @include font(14px, #026BB5, 800, 22px);
    }

    .price{
      @include font(14px, rgba(0, 0, 0, 0.85), 400, 22px);
    }
  }

  .itemContainer{
    width: 100%;
    max-width: 286px;
    margin-left: 24px;
    .itemWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin-top: 9px;
      margin-bottom: 0;
      margin-left: 24px;
    
      .title {
        @include font(14px, $devider-grey, $fw-regular, 22px);
      }
    
      .time {
        //min-width: 65px;
        @include font(14px, $header-color, $fw-regular, 22px);
      }
  
      .price{
        @include font(16px, rgba(0, 0, 0, 0.85), $fw-bold, 24px);
      }
    }
  }
}

.workshopPrices{
  .deviderPrice{
    display: flex;
    align-items: flex-start;
    //flex-direction: column;
    justify-content: space-between;
    .servicesTitle{
      @include fontMulishBold(14px, rgba(0, 0, 0, 0.85), $fw-bold,120%);
    }
    .priceBold{
      @include fontMulishBold(14px, rgba(0, 0, 0, 0.85), $fw-bold,120%);
    }
  }
  .deviderPriceTotal{
    display: flex;
    align-items: flex-start;
    //flex-direction: column;
    justify-content: space-between;
    .servicesTitle{
      @include fontMulishBold(18px, rgba(0, 0, 0, 0.85), $fw-bold,150%);
    }
    .priceTotal{
      @include fontMulishBold(18px, rgba(0, 0, 0, 0.85), $fw-bold,150%);
    }
  }
}

.promotions{
  .deviderPrice{
    display: flex;
    align-items: flex-start;
    //flex-direction: column;
    justify-content: space-between;
    //margin-top: 4px;
    .servicesTitle{
      @include fontMulishBold(14px, rgba(0, 0, 0, 0.85), $fw-bold,120%);
    }
    .priceBold{
      @include fontMulishBold(14px, rgba(0, 0, 0, 0.85), $fw-bold,120%);
    }
  }
  .itemWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //margin-top: 9px;
    margin-bottom: 4px;
    //margin-left: 0 !important;
    .rightPart{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 55%;
    }
    .name{
      //max-width: 125px;
      @include font(14px, $header-color, $fw-regular, 22px);
    }
    .price{
      width: max-content;
      margin-left: 20px; 
      @include font(14px, #026BB5, 700, 22px);
    }
    .valued{
      width: max-content;
      display: flex;
      align-items: flex-end;  
      @include font(12px, rgba(0, 0, 0, 0.45), 400, 20px);
    }
    .deleteIcon {
      color: $primary-color;
      cursor: pointer;
    }
  
    // .title {
    //   @include font(14px, $devider-grey, $fw-regular, 22px);
    // }
  
    // .time {
    //   //min-width: 65px;
    //   @include font(14px, $header-color, $fw-regular, 22px);
    // }

    // .price{
    //   @include font(16px, rgba(0, 0, 0, 0.85), $fw-bold, 24px);
    // }
  }

  .amount{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top:1px solid #F0F0F0;
    padding-top: 8px;
    @include fontMulishBold(18px, #026BB5, $fw-bold, 150%);

  }
}

.tabbyPromo{
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  p{
    margin: auto;
    @include font(16px, rgba(0, 0, 0, 0.85), 700, 24px);
  }
  img{
    width: 78px;
    height: 32px  ;
  }
}

.priceTotal{
  display: flex;
  align-items: center;
  min-width: 65px;
  @include font(16px, $black-color, 700, 24px);
}

// .devider {
//   display: flex;
//   align-items: center;
//   //flex-direction: column;
//   justify-content: space-between;
//   margin-top: 16px;
//   margin-bottom: 16px;
//   .priceBold{
//     @include fontMulishBold(14px, $black-color, 800, 120%);
//   }
// }

.basicService{
  flex-direction: column;
  align-items: flex-start
}

.engineMilliage {
  @include font(14px, $neutral, $fw-regular, 22px);
  width: 50px;
  display: inline-block;
}

.total {
  @include font(14px, $header-color, $fw-bold, 22px);
  margin-bottom: 10px;
}

.deliteIcon {
  color: $primary-color;
  cursor: pointer;

}

.emptyIcon {
  @include font(14px, $header-color, $fw-regular, 22px);
}


.deliteIcon:hover {
  color: $primary-light;
}

.servicesList {
  max-width: 350px;
  //margin-top: 50px;
  min-width: 240px;
  padding-top: 14px;
  padding-bottom: 20px;

  .head {
    @include font(14px, $header-color, $fw-bold, 22px);
    margin-bottom: 16px;
    span {
      margin-left: 8px;
      position: relative;
      top: 2px;
    }
  }
  .link {
    text-decoration: underline;
    cursor: pointer;
    @include font(14px, $primary-color, $fw-bold, 22px);
    margin-bottom: 20px;
    margin-top: 16px;
  }
}
.service {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  p {
    margin-bottom: 0;
  }
}

.partsList {
  max-height: 500px;
  overflow-y: scroll;
  .header {
    @include font(16px, $black-color, $fw-bold, 24px);
    margin: 16px 16px 16px 16px;
  }

  .content {
    display: flex;
    justify-content: space-between;
  }
  .leftBlock {
    flex: 50%;
  }
  .rightBlock {
    flex: 50%;
  }

  .row {
    @include font($color: $header-color);
    display: flex;
    padding: 9px;
    align-items: center;
    margin: 0 16px;
    gap: 9px;
    border-bottom: 1px solid #f0f0f0;
  }
}

@media (max-width: 1310px) {
  .carImage{
    width: 343px;
    height: 253px;
    margin-top: 30px;
    img{
      margin-left: 16px;
    }
  }
  .headInfo{
    margin-bottom: 0;
    margin-top: -10px;
    margin-left: 45px;
  }
  .rightBlockContainer {
    // background: url('../../Assets/carBackground.svg') no-repeat;
    // margin-top: 70px;
    width: 350px;

  
    // img {
    //   marg
    // }
  }
  .rightBlockInfo{
    margin-left: 17px;
    //width: 300px;
  }
}
// @media (max-width: 420px) {
//   .rightBlockInfo {
//     margin-left: 110px;
//   }
// }
