@import '../../../../../Theme/theme';

.contentWrapper {
  h2 {
    margin-top: 22px;
    font-size: 24px;
  }
}

.contentWrapper {
  max-width: 963px;
  margin: 0 auto;
  margin-top: 60px;
  padding: 0 24px;
  @include font(16px, #003356, $fw-regular, 24px);
  span{
    font-weight: 700;
  }
  .listP{
    margin-top: 8px;
    list-style: none;
    li{
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      // p{
      //   margin: 0;
      // }
    }
  }
  img{
    margin-right: 12px
  }

  .listWrapper {
    @include font(16px, #003356, $fw-regular, 24px);
  }

  h3 {
    @include font(24px, #003356, $fw-regular, 32px);
  }
}
