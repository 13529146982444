@import '../../../Theme/theme';

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  .containerWithInput {
    width: 100%;
    //display: flex;
    border-bottom: 1px solid #f0f0f0;

    .input {
      //height: 32px;
      //max-width: 160px;
      align-self: center;
    }

    .row {
      @include font($color: $header-color);
      display: flex;
      padding: 9px;
      align-items: center;
      gap: 9px;
      width: 100%;
    }
  }
}

@media (max-width: 420px) {
  .container {
    .containerWithInput {
      flex-wrap: wrap;
      .input {
        max-width: 100%;
      }
    }
  }
}
