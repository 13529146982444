@import '../../Theme/theme';

.checkBoxes {
  .form {
    display: flex;
  }

  .leftColumn {
    h2 {
      margin-top: 20px;
      font-size: 24px;
      line-height: 32px;
      color: rgba(0, 0, 0, 0.45);
    }
    display: flex;
    flex-direction: column;
    width: 50%;
    .consumablesSpan {
      color: rgba(0, 0, 0, 0.45);
    }
  }

  .inspection {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    width: 50%;

    h2 {
      margin-top: 20px;
      font-size: 24px;
      line-height: 32px;
      color: rgba(0, 0, 0, 0.45);
    }

    .extraNotesContainer {
      margin-top: 60px;

      .header {
        //display: flex;
        //justify-content: space-between;

        & > span > div:first-child {
          margin-left: 28px;
        }

        h2 {
          margin-top: 0;
        }

        .uploadButton {
          background: $white;
          border: 1px solid #d9d9d9;
          box-sizing: border-box;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
          border-radius: 40px;
        }
      }
    }
  }
}

@media (max-width: 670px) {
  .checkBoxes {
    .leftColumn {
      width: 100%;
    }

    .form {
      flex-direction: column;
    }

    .inspection {
      width: 100%;
      margin-left: 0;
    }
  }
}

/* @media (max-width: 380px) {
  .checkBoxes {
    .withInput > span:nth-child(2) {
      & > input {
        margin-bottom: 6px;
        min-width: 100px;
      }
    }
  }
  
} */
