@import '../../../../Theme/theme';

.boxShadow {
  box-shadow: 0px -7px 10px rgba(55, 78, 94, 0.02),
    0px 6px 20px rgba(55, 78, 94, 0.08);
  border-radius: 6px;
  box-sizing: content-box;
}

.container {
  display: flex;
  max-width: 1136px;
  padding: 16px;
  justify-content: space-between;
  margin: 0 auto 16px;
  //flex-wrap: wrap;

  .fullName {
    flex-grow: 2;
    flex-basis: 176px;
    flex-shrink: 1;
  }

  .personInfoContainer {
    display: flex;
    flex-grow: 3;
    flex-shrink: 2;
    gap: 12px;
    justify-content: space-between;
    //flex-wrap: wrap;
   

    .email {
      flex-basis: 176px;
    }

    .phone {
      flex-basis: 176px;
      text-align: end;
    }

    .tagDotsWrapper {
      display: flex;
    }

    .verticalDots {
      cursor: pointer;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}



@media screen and (max-width: 510px) {
  .container {
    
    .personInfoContainer {
      flex-wrap: wrap;

      .phone {
        text-align: start;
      }
    }

    
  }
}