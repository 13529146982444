@import '../../Theme/theme';

.headerContainer {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
  .filtersContainer {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;

    .statusContainer {
      display: flex;
      align-items: center;

      .label {
        margin-right: 6px;
        width: 51px;
      }

      .statusFilter {
          width: 150px;
          max-height: 40px;
      }
    }

    .dataPicker {
      border-radius: 6px;
      width: 150px;
    }
  }

  .totalMonth {
    span:first-child {
      @include font($size: 30px, $color: $devider-grey, $height: 40px);
    }
    span:nth-child(2) {
      @include font($size: 30px, $color: $black-color, $height: 40px);
    }
  }
}

.tabs {
  border: none;
  div[role*="tablist"]:not(#fd) {
    margin: 0 0px 16px 0px;
  }
}

.downloadIcon {
  color: $devider-grey;
  cursor: pointer;
}

.disabledIcon:not(#notExist) {
  cursor: auto;
  opacity: .2;
}


