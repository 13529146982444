@import '../../../../Theme/theme';

.mainWrapper {
  background-color: white;
  width: 100%;
  padding-top: 120px;
  height: max-content;
  .container {
    padding: 0;
    background-color: white;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    //margin-bottom: 7px;
    flex-direction: column;
    //margin-bottom: 28px;
    // .bannerContent {
    //   margin: 0 auto;
      .title{
        width: max-content;
        padding-left: 235px;
        .titleBox{
          height: 52px;
          overflow: hidden;
          margin-bottom: 5px;
          padding: 0;
          width: max-content;
          li{
            list-style: none;
            @include fontMulish(44px, #262626, 900, 54px);
            letter-spacing: 0.01em;
            width: max-content;
            top:0;
            position: relative;
            animation: slide 25s steps(5) infinite;
          }
          li::after{
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            left:0;
            background-color: white;
            border-left: 4px solid rgba(0, 0, 0, 0.85);
            animation: typing 5s steps(30) infinite;
          }
          @keyframes typing {
            40%,
            60%{
              left: calc(100%);
            }
            100%{
              left: 0;
            }
          }
          @keyframes slide{
            100%{
              top: -270px;
            }
          } 
        }
      }
      h1{
        margin-bottom: 8px;
        @include fontMulish(40px, #262626, 900, 46px);
        width: max-content;
        height: 46px;
      }
      .subTitle {
        @include fontMulishRegular(20px, $devider-grey, 600, 24px);
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 27px;
      }
      // .background{
      //    background-image: url(../../../../Assets/Background_image_1920.png);
      //   background-size: cover;
      //   //height: 416px;
      //   width: 1348px;
      //   max-width: 1440px;
      //   display: flex;
      // }
      .main{
        max-width: 1440px;
        display: flex;
        justify-content: flex-start;
        gap: 80px;
        
        .imagesContainer{
          display: flex;
          width: 794px;
          height: 509px;
          .imageView{
            width: 794px;
            height: 509px;
            position: absolute;
            z-index: 0;
          }
          .imageNoView{
            //position: absolute;
            z-index: -1;
            visibility: hidden;

          }
        }
        // .bannerImage{
        //   visibility: hidden;
        //   background-image: url(../../../../Assets/BannerBackground.svg);
        //   width: 828px;
        //   height: 100%;
        //   max-width: 828px;
        //   max-height: 389px;
        // }
        .form{
          max-width: 330px;
          .tabbyPromo{
            display: flex;
            justify-content: space-between;
            p{
              @include font(16px, rgba(0, 0, 0, 0.85), 700, 24px);
            }
          }
        }
      }
    //}
  }
}

@media screen and (max-width: 1250px) {
  .mainWrapper {
    padding-top: 60px;
    .container {
        height: 100%;
        .title{
          padding: 0;
          // margin: 0 auto;
          .titleBox{
            margin: 0 0 5px 38px;
            max-width: 450px !important;     
            li{
              margin: 0 auto;
              text-align: start !important;
              max-width: 450px !important;
              flex-wrap: nowrap;
              width: 100%;
            }
          }
        }
        .subTitle{
          text-align: start;
          margin-left: 38px;
        }
        .main{
          flex-direction: column;
          align-items: center;
          height: 100%;
          gap: 30px;
          .imagesContainer{
            width: 650px;
            height: 413px;
            .imageView{
              width: 650px;
              height: 413px;
            }
            .imageNoView{
              width: 650px;
              height: 413px;
            }
          }
          .form{
            width: 732px;
            height: 100%;
            max-width: 732px;
            //max-height: 389px;
          }
        }
    }
  }
}

@media screen and (max-width: 790px) {
  .mainWrapper {
    padding-top: 32px;
    .container{
      margin-bottom: 80px;
      .title{
        width: 100%;
      } 
      .main{
        width: 100% !important;
        justify-content: center;
        .form{
          width: 90% !important;
          margin: 0 auto;
          min-width: 300px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .mainWrapper {
    .container{
      margin-bottom: 30px;
      .main{
        .bannerImage{
          background-image: url(../../../../Assets/BannerBackgroundMob.svg) !important;
          width: 365px !important;
          height: 196.7px !important;
          margin: 0 auto;
        }
        .form{
          width: 100% !important;
          padding: 0 38px;
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .mainWrapper {
    .container {
      .main{
        height: 100%;
        justify-content: flex-end;
        .imageBox{
          height: 272px;
          width: 100% !important;
          list-style: none;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          padding: 0;
          overflow: hidden;
          width: max-content;
          margin-bottom: 0;
          li{
            height: 272px;
            max-width: 400px;
            position: relative;
            img{
              height: 272px;
              max-width: 400px;
            }
            text-align: center;
            animation: slideMobImage 10s steps(2) infinite;
          }
          li::after{
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            left:0;
            background-color: white;
            //display: none;
            border-left: none;
            top: 0;
            animation: flashImage 5s linear infinite;
          }
          @keyframes flashImage {
            0%{
              opacity: 1;
            }
            15%{
              opacity: 0;
            }
            85%{
              opacity: 0;
            }
            100%{
              opacity: 1;
            }
          }
          @keyframes slideMobImage{
            100%{
              top: -272px;
            }
          }
        }
      }
        .title{
          .titleBox{
            height: 32px;
            max-width: 343px;
            li{
              height: 32px;
              max-width: 343px;
              text-align: center;
              font-weight: 900 !important;
              font-size: 26px !important;
              line-height: 120% !important;
              animation: slideMob 25s steps(5) infinite;
            }
            li::after{
              //display: none;
              border-left: none;
              top: 0;
              animation: flash 5s linear infinite;
            }
            @keyframes flash {
              0%{
                opacity: 1;
              }
              15%{
                opacity: 0;
              }
              85%{
                opacity: 0;
              }
              100%{
                opacity: 1;
              }
            }
            @keyframes slideMob{
              100%{
                top: -160px;
              }
            }
          }
        }
        h1{
           font-family: RobotoRegular, sans-serif !important;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          height: 80px;
          font-weight: 00 !important;
          font-size: 30px !important;
          line-height: 40px !important;
        }
        .subTitle{
          font-weight: 700;
          font-size: 14px;
          line-height: 120%;
        }
      }
  }
}
@media screen and (max-width: 420px) {
  .mainWrapper {
    .container {
      .title{
        .titleBox{
          li{
            font-size: 22px !important;
          }
        }
      }
      .subTitle{
        margin-bottom: 7px;
      }
      .main{
        height: 100%;
        justify-content: flex-end;

        .imageBox{
          height: 272px;
          width: 100% !important;
          list-style: none;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          padding: 0;
          overflow: hidden;
          width: max-content;
          margin-bottom: 0;
          li{
            height: 272px;
            max-width: 400px;
            position: relative;
            img{
              height: 272px;
              max-width: 400px;
            }
            text-align: center;
            font-weight: 900 !important;
            font-size: 38px !important;
            line-height: 120% !important;
            animation: slideMobImageSecond 10s steps(2) infinite;
          }
          @keyframes slideMobImageSecond{
            100%{
              top: -204px;
            }
          }
        }

        .bannerContent{
          display: flex;
        }
        .imageBox{
          height: 204px;
          li{
            width: 300px;
            height: 204px;
            img{
              width: 300px;
              height: 204px;
            }
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 428px) {
//   .mainWrapper {
//     .container {
//       .main{
//         height: 100%;
//       }
//       .bannerContent{
//         .title{
//           .titleBox{
//             li{
//               font-family: RobotoRegular, sans-serif !important;
//               font-weight: 500 !important;
//               font-size: 30px !important;
//               line-height: 40px !important;
//             }
//           }
//         }
//         h1{
//            font-family: RobotoRegular, sans-serif !important;
//           display: flex;
//           align-items: flex-end;
//           justify-content: center;
//           height: 80px;
//           font-weight: 500 !important;
//           font-size: 30px !important;
//           line-height: 40px !important;
//         }
//         .subTitle{
//           font-weight: 400;
//           font-size: 16px;
//           line-height: 24px;
//         }
//       }
//     }
//   }
// }