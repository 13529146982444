@import '../../../../Theme/theme';

.container {
  background:linear-gradient(0deg, rgba(2, 107, 181, 0.3), rgba(2, 107, 181, 0.3)), linear-gradient(264.41deg, #A3EFD3 4.48%, #67BFDC 59.81%, #2084CA 97.42%);
  //padding: 64px 16px;
  .tabbyPromo {
    max-width: 1216px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 140px;
    margin-bottom: 40px;

    .tabbyWomen{
      width: 270px;
      height: 404px;
      object-fit: cover;
    }
    .titles{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h1 {
        @include fontMulish(40px, white, 900, 48px);
        text-align: center;
        margin-bottom: 24px;
      }
      img{
        width: 164px;
        height: 68px;
      }
      p{
        @include font(12px, white, 700, 24px);
        text-align: center;
        margin-bottom: 0;
        margin-top: 8px;
        a{
          @include font(12px, white, 700, 24px);
          text-decoration: underline;
        }
      }
    }   
  }
}

@media (max-width: 900px) {
  .container{
    .tabbyPromo{
      gap: 50px;
    }
  }
}
@media (max-width: 700px) {
  .container{
    .tabbyPromo{
      gap: 0px;
    }
  }
}
@media (max-width: 550px) {
  .container{
    .tabbyPromo{
      gap: 0px;
    }
    .tabbyWomen{
      margin-top: 70px;
      width: 215px !important;
      height: 334px !important;
      object-fit: cover !important;
      position: relative;
      left: -5px;
    }
    .titles{
      //position: relative;
      margin-top: 52px;
      justify-content: flex-start !important;
      h1{
        font-size: 26px !important;
        line-height: 120% !important;
        margin-bottom: 0px !important;
      }
      img{
        margin-top: 8px;
        width: 116px !important;
        height: 48px !important;
      }
    }
  }
}
@media (max-width: 420px) {
  .container{
    .tabbyPromo{
      justify-content: flex-start;
    }
    .tabbyWomen{
      left: -15px;
    }
    .titles{
      position: absolute !important; 
      margin-top: 52px;
      justify-content: flex-start !important;
      left: 200px !important;
      margin-right: 8px;
    }
  }
}
@media (max-width: 390px) {
  .container{
    .titles{
      left: 190px !important;
    }
  }
}