@import '../../../../../Theme/theme';

.boxShadow {
  box-shadow: 0px -7px 10px rgba(55, 78, 94, 0.02),
    0px 6px 20px rgba(55, 78, 94, 0.08);
  border-radius: 6px;
  box-sizing: content-box;
}

.binIcon {
  color: $primary-color;
  cursor: pointer;
}

.container {
  width: 550px;
  padding: 16px;

  .imageWithNameContainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    img {
      width: 200px;
      height: 127px;
    }

    .carNameContainer {
      margin-left: 16px;
      width: 250px;

      .carInfo {
        @include font(24px, $black-color, $fw-regular, 32px);
      }

      .millage {
        @include font($color: $devider-grey);
        margin-right: 16px;
        display: inline-block;
      }
    }
  }

  .formContainer {
    .millagePrompt {
      max-width: 254px;
      text-align: end;
      margin: 0 0 24px auto;
      @include font($color: $primary-color);
    }
  }

  label[for='car-info_change-gears'] {
    &::after {
      visibility: hidden;
    }
  }

  .labelGears {
    @include font($color: $devider-grey);
  }
}

@media screen and (max-width: 472px) {
  .container {
    .imageWithNameContainer {
      justify-content: center;
    }
  }
}
