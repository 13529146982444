@import '../../Theme/theme';

.container {
  padding: 4px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  max-width: 698px;
}

.workshopInfo {
  flex: 2 3;
  border-right: 1px solid #F0F0F0;
}

.headline {
  min-width: 95px;
  max-width: 375px;
  word-wrap: break-word;
  padding-right: 9px;
  //padding-left: 9px;
}

.bookingInfo {
  flex: 1 2;
}

.imageAndDescription {
  display: flex;
  padding: 12px 5px 5px 0;
  //padding-left: 0;

  .modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    .workshopImage{
      object-fit: cover;
      height: 200px;
      width: 200px;
    }
  
    .moreInfo{
      @include font(12px, #026BB5, 700, 150%);
      text-transform: uppercase;
      margin: 0 auto;
      margin-top: 4px;
      img{
        width: 12px;
        height: 5px;
        margin-bottom: 4px;
        margin-left: 2px;
      }
      cursor: pointer;
    }
  }

  .mobVisible{
    display: none;
    .link {
      @include font(12px,rgba(0, 0, 0, 0.45), 400, 20px);
      //margin-left: 9px;
      //height: 16px;
      display: flex;
      flex-direction: row;
      
      .google{
        width: 16px;
        height: 16px;
        margin: 0 4px;
        margin-top: 2px;
      }
      .rateBox{
        height: 12px;
        margin-right: 8.5px;
        margin-left: 4px;
        .rate{
          display: flex;
          flex-direction: row;
          height: 20px;
          align-items: center;
          //align-items: flex-end;
          li{
            width: 12px;
            padding-bottom: 4px;
            margin-right: 1.5px;
            svg{
              height: 12px;
              width: 12px;
            }
          }
        }
      }
      a{
        text-decoration:none;
      }
    }
    .bookingInfoItem {
      display: flex;
      justify-content: space-between;
      p:first-child {
        margin-bottom: 0;
      }
      p {
        @include font(12px, $devider-grey, 400, 20px);
      }
  
      span {
        @include font(14px, $primary-color, 700, 20px);
      }
    }
    // .bookingInfoItem + .bookingInfoItem {
    //   margin-top: 4px;
    // }
  }
  .saveButton {
    display: flex;
    justify-content: center;
  }
  .price {
    @include fontMulish(22px, rgba(0, 0, 0, 0.85), 800, 24px);
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0;
  }
  .parts{
    //margin-top: 20px;
    p{
      text-align: center;
      margin: 0;
      margin-top: 8px;
      @include font(9px, $header-color, 400, 120%);
    }
  } 
}

.saveButton {
  display: flex;
  justify-content: center;
}

.bookingInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 18px 0 26px;
  //padding-top: 32px;
  min-width: 110px;
  max-width: 204px;

  .bookingInfoItem {
    display: flex;
    justify-content: space-between;
    p:first-child {
      margin-bottom: 0;
    }
    p {
      @include font(12px, $devider-grey, 400, 20px);
    }

    span {
      @include font(14px, $primary-color, 700, 20px);
    }
  }
  // .bookingInfoItem + .bookingInfoItem {
  //   margin-top: 4px;
  // }
}
.price {
  @include fontMulish(22px, rgba(0, 0, 0, 0.85), 800, 24px);
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  margin-top: 12px;
  margin-bottom: 0;
}

.breakdown {
  text-align: center;
  margin-bottom: 12px;
  cursor: pointer;
  text-decoration-line: underline;
  @include font(9px, $primary-dark, $fw-bold, 120%);
}

.popoverTitle {
  @include font(16px, $header-color, $fw-bold, 24px);
  text-align: start;
}

.workshopName {
  cursor: pointer;
  @include fontMulishBold(22px, $header-color, 700, 26px);
}

.rightPart{
  .parts{
    //margin-top: 20px;
    p{
      text-align: center;
      margin: 0;
      margin-top: 8px;
      @include font(9px, $header-color, 400, 120%);
    }
  }
  .workshopPoints{
    margin-top: 4px;
    .partner {
      & > div:first-child {
        padding-bottom: 2px;
      }
      display: flex;
      align-items: center;
      .checkIcon {
        width: 16px;
        height: 16px;
      }
      span {
        display: inline-block;
        align-self: flex-end;
        margin-left: 4px;
        @include font(12px, rgba(0, 0, 0, 0.45), 400, 20px);
      }
    }
  }
}

@media (max-width: 620px) {
  .workshopInfo {
    width: 100%;
    flex: 2 3;
    border-right: 0;
    //border-bottom: 1px solid #F0F0F0;
    margin-bottom: 4px;
    .workshopName{
      //width: max-content;
      font-size: 18px;
      line-height: 120%;
    }
  }
  .container {
    //flex-direction: column;
    width: 100%;
    align-items: flex-start;
    //border-bottom: 2px solid rgba(0, 0, 0, 0.45);
    //margin: 0 auto;
    padding: 0 16px;
    //padding-bottom: 12px;
    box-shadow: 0px 0px 4px rgba(30, 72, 102, 0.03), 0px 3px 8px rgba(30, 72, 102, 0.1);
    //border-radius: 20px;
  }
  .headline {
    margin-bottom: 4px;
  }
  .button{
    width: 160px;
  }
  .bookingInfo {
    //flex-direction: row;
    display: none;
    width: 100%;
    max-width: none;
    padding: 0;
    justify-content: space-between;
    .bookingInfoItem{
      justify-content: flex-start;
      p{
        margin-right: 6px;
      }
    }
  }

  .leftPart {
    margin-top: 12px;
  }

  .rightPart {
    .parts{
      text-align: start;
    }
    .workshopPoints{
      display: none;
    }
  }

  .price {
    margin-top: 4px;
    font-size: 18px;
    line-height: 120%;
  }

  // .button {
  //   width: 127px;
  //   height: 40px;
  //   padding: 8px 16px;
  //   margin-top: 8px;
  // }

  .imageAndDescription {
    justify-content: space-between;
    padding-top: 4px;
    .workshopImage{
      object-fit: cover;
      margin-top: 8px;
      width: 144px !important;
      height: 144px !important; 
      margin-right: 12px !important;
    }
    .mobVisible{
      display: block;
      border-left: 1px solid #F0F0F0;
      padding-left: 12px;
      .price{
        margin-top: 12px;
        margin-bottom: 8px;
      }
    }
  }
}

@media (max-width: 520px) {
  .imageAndDescription{
    justify-content: space-between;
    .mobVisible{
      border-left: none;
      padding-left: 0;
    }

  }
}

@media (max-width: 350px) {
  .container {
    flex-direction: column;
    width: 280px;
    align-items: center;
    margin: 0 auto;
  }
}
