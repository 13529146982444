.span{
  display: flex;
  flex-direction: row;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 220px;
}
@media screen and (max-width: 520px) {
  .span{
    max-width: 109px;
  }
}
