@import '../../../../../Theme/theme';

.navLinksContainer {
  margin-top: 74px;
  display: flex;
  flex-direction: column;
}

.mainLink {
    height: 40px;
    cursor: pointer;
    padding: 6px 0 8px 40px;
    margin: 2px 0px;
  }

.landingDrawer {
  div[class='ant-drawer-body']::after {
    content: '';
    width: 256px;
    height: 256px;
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: 0;
    background-image: url(../../../../../Assets/sideBarImage.svg);
    background-repeat: no-repeat;
    background-position: right bottom;
  }
}

.primaryColor {
  color: $primary-dark;
}


