@import './../../Theme/theme';

.addCar{
    h1[class='title'] {
        display: none;
    }
    div[class='content']{
        margin-left: 100px;
        margin-top: 80px;
    }
}

@media screen and (max-width: 700px) {
    div[class='content']{
        margin-left: 0 !important;
        margin-top: 0 !important;
    }
}