@import '../../../../Theme/theme';

.container {

  .priceItem {
    display: flex;
    justify-content: space-between;
    width: 340px;
    margin-bottom: 8px;
    @include font(14px, rgba(0, 0, 0, 0.85), 500, 22px);
  }

  .chieldPrices{
    display: flex;
    justify-content: space-between;
    width: 320px;
    margin-left: 20px;
    margin-bottom: 8px;
    @include font(14px, $devider-grey, 500, 22px);
  }

  .price{
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.85);
  }
  
  .divider {
    margin: 0 0 8px 0;
  }

  .totalWrapper {
    display: flex;
    justify-content: space-between;
    @include font(16px, $header-color, $fw-bold, 24px);
  }

}

@media (max-width: 380px) {
  .container {
    .priceItem {
      width: 300px;
    }
    .chieldPrices{
      display: flex;
      justify-content: space-between;
      width: 280px;
      margin-left: 20px;
      margin-bottom: 8px;
      @include font(14px, $devider-grey, 500, 22px);
    }
  }
 
}
