@import '../../../../../../Theme/theme';

.container {
  
  .cardContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0 24px;
    h3{
      @include font(18px, rgba(0, 0, 0, 0.85), 600, 28px);
      margin-bottom: 0;
    }
    div[class='otherText']{
      text-align: left;
      max-height: 64px;
      span{
        @include font(16px, rgba(0, 0, 0, 0.85), 500, 24px);
      }
    }
    .subtitle {
      @include font(18px, rgba(0, 0, 0, 0.45), 500, 28px);
    }
  }
  .devider{
    @include font(18px, rgba(0, 0, 0, 0.45), 500, 28px);
  }
  .link {
    display: block;
    margin-bottom: 24px;
    @include font($color: $primary-color, $weight: 600);
    text-decoration: underline;
  }
}

.buttons{
  margin-top: 50px;
  padding-bottom: 17px;
  display: flex;
  justify-content: end;
}

.partsList {
  max-height: 500px;
  //overflow-y: scroll;
  .header {
    h4{
      @include font(24px, #5B8DB4, 500, 32px);
      margin: 16px 0 12px;
    }
    p{
      @include font(14px, rgba(0, 0, 0, 0.85), 700, 22px);
      margin-bottom: 10px;
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
  }
  .leftBlock {
    flex: 50%;
  }
  .rightBlock {
    flex: 50%;
  }

  .row {
    @include font(14px, rgba(0, 0, 0, 0.85), 700, 22px);
    display: flex;
    padding: 17px 0;
    align-items: center;
    //margin: 0 16px;
    gap: 9px;
    border-bottom: 1px solid #f0f0f0;
  }
}
@media (max-width: 570px) {
  .container {
    .cardContainer {
      h3{
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .buttons{
    margin-top: 28px;
  }
}
@media (max-width: 540px) {
  .container {
    .cardContainer {
      gap: 0 16px;
    }
  }
}
