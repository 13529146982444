@import '../../../Theme/theme';

.container {
  max-width: 640px;
  padding: 20px;
  .subContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
  }
  .addCardForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 12px;
    width: 400px;

    button {
      margin-left: 120px;
      margin-top: 12px;
    }
  }
}

.alertBody {
  padding-top: 32px;
  @include font(16px, $black-color, $fw-bold, 24px);
  display: flex;
  gap: 16px;
}
