@import "../../../../../Theme/theme";

.form {
    max-width: 360px;

    .header {
        @include font(14px, $header-color, $fw-bold, 22px);
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .input {
        color: $primary-color;
      }
}