@import '../../Theme/theme';


.searchContainer {
    display: flex;

    img {
        margin: 0 0 20px 10px;
        cursor: pointer;
    }

    input[type=text] {
        box-sizing: border-box;
        border: 1px solid $border-color;
        width: 100%;
        height: 32px;
        padding: 0 12px;
        border-radius: 6px;
        font-size: 14px;
        outline: none;
        text-overflow: ellipses;
        margin-bottom: 20px;
        background-position: 270px -10px;
        background-repeat: no-repeat;
    }
}

