@import '../../../../../../Theme/theme';

.loginForm {
  margin-top: 24px;
}

.input {
  color: $primary-color;
}

.acceptContainer {
  position: relative;

  .errorMessage {
    position: absolute;
    color: red;
    top: -18px;
    left: 30%;
  }
}

.checkBoxText {
  @include font($size: 12px, $color: $font-color);
  max-width: 300px;
  margin-bottom: 24px;
  align-items: center;

  a {
    @include font($size: 12px, $color: $primary-color);
  }
}

.checkBoxSaveText {
  @include font($size: 12px, $color: $font-color);
}

.checkboxSave {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}

.buttonSocial {
  width: 100%;
  display: block;
  margin: 20px 0;

  .btnText {
    margin-left: 12px;
  }
}

.tabbyInfo{
  width: 100%;
  max-width: 360px;
  border: 1px solid #5AFEAE;
  border-radius: 7px;
  height: 40px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  img{
    // width: 96px;
    // height: 40px;
  }
  p{
    @include font(16px,rgba(0, 0, 0, 0.85),700,24px);
    //min-width: 230px;
    margin: auto;
  }
}
.tabbyInfo:hover{
  cursor: pointer;
}
.paymentcontainer {
  h2{
    @include fontMulishBold(24px, $header-color, 900, 32px);
    margin-left: 0 !important;
    margin-bottom: 0;
  }
  .subtitle {
    @include font(18px, $primary-color, $fw-bold, 28px);
    //@include font($size: 18px, $color: $primary-color);
    max-width: 360px;
  }

  .withCardBook {
    //margin-top: 4px;
    @include font(14px, rgba(0, 0, 0, 0.85), 400  , 22px);

    // @include font(14px, $devider-grey, $fw-bold, 20px);
    //@include font($color: $devider-grey);
    margin-bottom: 12px;
  }
  .warrantyOEM{
    @include font(14px, $devider-grey, $fw-bold, 20px);

    // @include font(14px, rgba(0, 0, 0, 0.85), 400  , 22px);
    margin-bottom: 20px;
  }
  .paymentSaved{
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    p{
      @include font(14px, rgba(0, 0, 0, 0.45), 700, 22px);
      margin: 0;
    }
  }
  .paymentSaved:hover{
    cursor: pointer;
  }
}

.modalWarning{  
  //div[class='ant-modal-confirm-btns']{
  button{
      align-items: center;
      padding: 5px 28px;
  
      width: 172px;
      height: 32px;
  
      /* Primary/branding dark blue */
      @include font(14px,#FFFFFF, 500, 22px);
      background-color: #026BB5 !important;
      box-shadow: 0px 7px 4px rgba(0, 0, 0, 0.043);
      border-radius: 40px;
  }
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

