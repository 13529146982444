@import '../../../../Theme/theme';


.partner {
  & > div:first-child {
    padding-bottom: 2px;
  }
  display: flex;
  align-items: center;
  .checkIcon {
    width: 14px;
    height: 16px;
  }
  span {
    display: inline-block;
    align-self: flex-end;
    margin-left: 5px;
    @include font($color: $devider-grey, $height: 20px);
  }
}

.description {
  margin-left: 12px;
  flex: 2 3;
  max-width: 290px;
  min-width: 191px;
  margin-top: -4px;

  .moreInfo{
    @include font(12px, #026BB5, 700, 150%);
    text-transform: uppercase;
    margin: 0;
    margin-top: 12px;
    img{
      width: 12px;
      height: 5px;
      margin-bottom: 4px;
      margin-left: 2px;
    }
    cursor: pointer;
  }

  .link {
    @include font(12px,rgba(0, 0, 0, 0.45), 400, 20px);
    //margin-left: 9px;
    //height: 16px;
    display: flex;
    flex-direction: row;
    
    .google{
      width: 16px;
      height: 16px;
      margin: 0 4px;
      margin-top: 2px;
    }
    .rateBox{
      height: 12px;
      margin-right: 8.5px;
      margin-left: 4px;
      .rate{
        display: flex;
        flex-direction: row;
        height: 20px;
        align-items: center;
        //align-items: flex-end;
        li{
          width: 12px;
          padding-bottom: 4px;
          margin-right: 1.5px;
          svg{
            height: 12px;
            width: 12px;
          }
        }
      }
    }
    a{
      text-decoration:none;
    }
  }
  .specialisations{
    // .workshopImage{
    //   display: none;
    // }
    .specialist {
      @include font(14px, rgba(0, 0, 0, 0.85), 400, 22px);
      margin: 12px 0;
      .specialistItem {
        margin: 4px 0;  
        .checkIcon{
          width: 20px;
          height: 20px;
        }
        & > img {
          margin-right: 12px;
        }
      }
    }
  }
}

.marginLeftZero {
    margin-left: 0;
}



@media (max-width: 620px) {
  .description {
    min-width: 0px;
    //width: 160px;
    max-width: none;
    flex: none;
    margin: 0;
    .moreInfo{
      margin-top: 6px;
      text-align: center;
    }
    .link{
      display: none;
    }
  }

  // .workshopImage{
  //   display: block !important;
  //   object-fit: cover;
  //   margin-top: 8px;
  //   width: 144px;
  //   height: 144px;
  //   margin-right: 12px;
  // }
  
  .specialisations{
    display: flex;
    flex-direction: row;
    .specialist{
      margin-top: 6px !important;
      margin-right: 12px !important;
    }
  }
}

@media (max-width: 565px) { 
  .specialisations{
    .specialist{
      font-size: 12px !important;
      .specialistItem {
        & > img {
          margin-right: 6px !important;
        }
      }
    }
  }
}
@media (max-width: 540px) { 
  .specialisations{
    .specialist{
      font-size: 10px !important;
      .specialistItem {
        & > img {
          margin-right: 6px !important;
        }
      }
    }
  }
}
@media (max-width: 520px) { 
  .specialisations{
    flex-direction: column;
    .specialist{
      margin: 0 !important;
      font-size: 12px !important;
      .specialistItem {
        display: none;
      }
    }
  }
  .description{
    max-width: 160px;
  }

  .workshopImage{
    margin-right: 0;
  }
}