@import '../../../../../../Theme/theme';

.container {
  .devider {
    @include font(18px, $devider-grey, 500, 28px);
    margin: 35px 0 0 0;
  }
  .optionItem {
    width: 100%;
    display: flex;
    border-bottom: solid #F0F0F0 1px;
    padding: 12px;
  }

  .deliteIcon {
    color: $primary-color;
    cursor: pointer;
  }

  .deliteIcon:hover {
    color: $primary-light;
    margin-left: auto;
  }

  .optionTitle {
    @include font(16px, $header-color, 500, 24px)
  }
}

.noteAnimate-exit-active {
  animation: noteAnimate 800ms ease-out forwards;
}
 
.noteAnimate-enter-active {
  animation: noteAnimate 800ms ease-in alternate-reverse;
}
 
@keyframes noteAnimate {
  0% {
    transform: translateX(0);
  }
  30% {
    opacity: .7;
    transform: translateX(30px);
  }
  100% {
    opacity: 0;
    transform: translateX(-200px);
  }
}