@import '../../../../Theme/theme';

.menuItem {
    display: flex;
    cursor: pointer;
    
    p {
        margin-left: 10px;
        line-height: 16px;
    }
}

.red {
    color: #FF4D4F;
}

.green {
    color: rgb(10, 136, 41);
}

.yellow {
    color: rgb(221, 218, 20);
}