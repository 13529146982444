@import '../../Theme/theme';

.nodeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .titleNode {
    padding: 4px;
    max-width: 85%;
    @include font($color: $header-color);
  }
  .price {
    width: 70px;
    margin-right: 4px;
    text-align: right;
    padding: 1px;
    @include font(14px, $devider-grey, $fw-regular, 22px);
  }
}

