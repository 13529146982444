@import '../../../Theme/theme';
.container {
  position: relative;

  .badge {
    display: flex;
    align-items: center;

    img {
      height: 24px;
      width: 24px;
    }
    p {
      margin: 0px;
      margin-left: 14px;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #40a9ff;
    }
  }

  .carImg {
    display: flex;
    align-items: center;
    min-width: 150px;
  }

  .carImage {
    margin-top: 40px;
    height: 152px;
    width: 200px;
  }

  // .carImageWithCard {
  //   margin-top: 70px;
  // }
}

.cardContainer {
  position: absolute;
  top: 24px;
  @include font (12px, $header-color, $fw-regular, 20px);
  text-transform: uppercase;
  display: flex;

  .cardImage {
    margin-right: 9px;
    margin-top: 10px;
  }
}

.time {
  display: flex;
  position: absolute;
  align-items: center;

  .amount {
    @include font($color: $devider-grey);
    display: inline-block;
    vertical-align: text-bottom;
  }

  .price {
    margin: 0px;
    margin-left: 14px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #40a9ff;
    white-space: nowrap;
  }
}
