@import '../../../Theme/theme';

.time {
  display: flex;
  position: absolute;
  align-items: center;

  img {
    height: 24px;
    width: 24px;
  }
  .danger {
    color: #cf1322;
  }
  p {
    margin: 0px;
    margin-left: 14px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #40a9ff;
  }
}
