@import '../../../Theme/theme';

.contain {
  max-width: 476px;
  height: 680px;
  border-radius: 6px;
  padding: 56px;
  background-color: $white;
  margin: 70px auto 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .header {
    @include font(38px, $font-blue, 500, 46px);
  }

  .alert {
    height: 40px;
    border-radius: 6px;
    @include font;
    position: absolute;
    left: 56px;
    right: 56px;
    top: 15px;
  }
}

.forgotPassword {
  @include font();
  margin: 0 auto;
  max-width: 360px;
  margin-bottom: 16px;
  text-align: left;

  .subTitle {
    @include font(12px);
    text-align: left;
    margin-top: 12px;
  }
  .link {
    cursor: pointer;
    text-decoration: underline;
  }
}

@media (max-width: 560px) {
  .contain {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10%;
    width: 100vw;
    height: 100vh;
    background-color: $white;
  }
}
