@import '../../../../../Theme/theme';

.active {
  color: $primary-dark !important;
}


.mainLink {
  height: 40px;
  cursor: pointer;
  padding: 6px 0 8px 40px;
  margin: 2px 0px;
}

.mainLink.active {
  color: $primary-dark !important;
  border: 2px solid $background-light;
  box-shadow: 0px 11px 12px rgba(55, 78, 94, 0.08);
  border-radius: 20px 0px 0px 20px;
  background-color: $background-light;
}

.mainLink:hover {
    border: 2px solid $background-light;
    box-shadow: 0px 11px 12px rgba(55, 78, 94, 0.08);
    border-radius: 20px 0px 0px 20px;
    background-color: $background-light;
  }