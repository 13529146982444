@import '../../../Theme/theme';

.stepsContainer {
  max-width: 750px;
  margin: 0 auto;
  padding-right: 40px;
//   margin-bottom: 30px;
}

.paymentContainer{
  position: absolute;
  top: 80%;
  left: 1%;
  .payments{
    position: fixed;
    //top: 90%;
    @include fontMulishBold(18px, rgba(0, 0, 0, 0.45), 800, 120%);
    p{
      margin-bottom: 4px;
    }
    .paymentIcons{
      img{
        margin-right: 4px;
      }
    }

  }
}

.containerMinSize {
  max-width: 750px;
  margin: 0 auto;
  display: none;
}

@media screen and (max-width: 1200px) {
  .paymentContainer{
    display: none;
  }
}

@media screen and (max-width: 675px) {
  .stepsContainer {
    display: none;
  }
  .containerMinSize {
    .steps{
      // div[class='ant-steps-item-icon']{

      //    background: #026BB5;
      //   border-color: #026BB5;
      // }
    }
    margin: 0 16px;
    display: block;
    // margin-bottom: 30px;
  }
}
