@import '../../Theme/theme';

.container {
  //margin: 0 60px;
  max-height: 600px;
  overflow-y: scroll;
  max-width: 600px;
  margin: 0 auto;
  padding: 24px 20px 17px 20px;
}

.disabled {
  color: #bfbfbf;
}

.editButton {
  width: 90px;
  cursor: pointer;
  text-align: center;
  background: #ffffff;
  border: 1px solid #a2ceef;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 40px;
}

.editButton:active {
  transform: scale(.96);
}




.editButton:hover {
  color: $primary-color;
  transition: all .4s ease-in-out;
}
/* .button {
  margin-left: 12px;
  height: 40px;
  border: none;
}

.saveButton {
  background: linear-gradient(to right, $primary-light, $blue-light);
  width: 160px;
}
 */
