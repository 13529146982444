@import "./fonts";
@import "./variables";



@mixin font($size: 14px, $color: $font-color, $weight: $fw-regular, $height: 22px) {
    font-family: LatoRegular, sans-serif;
    font-size: $size;
    color: $color;
    font-style: normal;
    font-weight: $weight;
    line-height: $height;
}

@mixin fontBlack($size: 14px, $color: $font-color, $weight: $fw-regular, $height: 22px) {
    font-family: LatoBlack, sans-serif;
    font-size: $size;
    color: $color;
    font-style: normal;
    font-weight: $weight;
    line-height: $height;
}

@mixin fontRoboto($size: 14px, $color: $font-color, $weight: $fw-regular, $height: 22px) {
    font-family: RobotoRegular, sans-serif !important;
    font-size: $size;
    color: $color;
    font-style: normal;
    font-weight: $weight;
    line-height: $height;
}

@mixin fontMulish($size: 14px, $color: $font-color, $weight: $fw-regular, $height: 22px) {
    font-family: MulishBlack, sans-serif !important;
    font-size: $size;
    color: $color;
    font-style: normal;
    font-weight: $weight;
    line-height: $height;
}

@mixin fontMulishRegular($size: 14px, $color: $font-color, $weight: $fw-regular, $height: 22px) {
    font-family: MulishRegular, sans-serif !important;
    font-size: $size;
    color: $color;
    font-style: normal;
    font-weight: $weight;
    line-height: $height;
}
@mixin fontMulishBold($size: 14px, $color: $font-color, $weight: $fw-regular, $height: 22px) {
    font-family: MulishBlack, sans-serif !important;
    font-size: $size;
    color: $color;
    font-style: normal;
    font-weight: $weight;
    line-height: $height;
}



