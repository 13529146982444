@import '../../Theme/theme';

.boxShadow {
  box-shadow: 0px -7px 10px rgba(55, 78, 94, 0.02),
    0px 6px 20px rgba(55, 78, 94, 0.08);
  border-radius: 6px;
  box-sizing: content-box;
  // border: solid red 1px;
}

.addOpacity {
  opacity: .3;
}

.pendingConfirmation {
  position: relative;
}

.pendingConfirmation::before {
  content: 'Pending workshop confirmation';
  position: absolute;
  top: -62px;
  @include font(24px, #40A9FF, $fw-regular, 32px)
}

.container {
  display: flex;
  //max-width: 1136px;
  padding: 16px;
  justify-content: space-between;
  margin: 16px 16px;
}

.horMarginNone {
  margin: 16px 0px;
}

.topLeftWrapper {
  display: flex;
  justify-content: flex-start;
}
.disableLink {
  pointer-events: none;
}

.canClick {
  cursor: pointer;
}

.carName {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0px;
}

.servicesInfo {
  margin-left: 20px;
  flex-basis: 462px;
  padding-right: 10px;
  .carName {
    @include font(24px, $header-color, $fw-regular, 32px);
  }
  .list {
    padding-left: 15px;
    li {
      color: $primary-color;
    }
  }
  span {
    @include font(14px, $header-color, $fw-regular, 22px);
  }

  p {
    @include font(14px, $header-color, $fw-regular, 22px);
  }
}

.minWidthForJob {
  min-width: 365px;
}

.workshopInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: 350px;
  margin-left: 32px;

  &::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    border: 1px solid #f0f0f0;
    left: -32px;
  }

  .leftBlock {
    display: flex;
    flex-direction: column;
      .leftSide {
        display: flex;
        img {
          width: 24px;
          height: 24px;
        }
        p {
          @include font(16px, $primary-color, $fw-regular, 24px);
          margin-left: 12px;
          margin-bottom: 0;
        }
      }
      .rightPrice{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
      .rightPrice div:first-child {
        @include font(18px, #40A9FF, $fw-regular, 28px);
        
      }

      .orderNumber span:first-child {
        @include font(14px, $devider-grey, $fw-regular, 22px);
      }

    .pickUp {
      @include font(14px, $devider-grey, $fw-regular, 22px);
      //margin-left: 36px;
      margin-bottom: 6px;
      display: flex;
      justify-content: space-between;

      // & > span + span {
      //   margin-left: 8px;
      // }
      & > span:first-child {
        display: inline-block;
        width: 80px;
      }
    }
  }

  .pickUp + .pickUp {
    margin-bottom: 18px;
  }

  .dateWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;

    .orderDate {
      display: flex;

      .date {
        @include font(16px, $header-color, $fw-regular, 24px);
        margin-left: 12px;
        margin-bottom: 4px;
      }
    }
    .estimated {
      @include font(14px, $devider-grey, $fw-regular, 22px);
      margin-left: 36px;
    }
  }

  .rightBlock {
    display: flex;
    flex-direction: column;
    margin-top: -4px;
    .customerInfo {
      margin-bottom: 20px;
      .name {
        @include font(18px, $primary-color, $fw-regular, 28px);
        max-width: 328px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        //margin-right: 40px;
      }
      .address {
        @include font(14px, $devider-grey, $fw-regular, 22px);
        margin-bottom: 0;
        margin-right: 40px;
      }
    }
    .notReview {
      margin-top: 8px;
      @include font(14px, #cf1322, $fw-regular, 22px);
    }
  }
}

.buttonContainer {
  margin-right: 10px;
  //display: flex;
  justify-content: space-between;
  // .checkBox{
  //   @include font(14px, rgba(0, 0, 0, 0.85), 400, 22px);
  //   // display: flex;
  //   // align-items: center;
  // }
}

.twoButtonContainer {
  display: flex;
}

.allDoneContainer{
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  .checkBox{
    margin-top: 10px;
    @include font(14px, rgba(0, 0, 0, 0.85), 400, 22px);
    // display: flex;
    // align-items: center;
  }
}

.alertBody {
  padding-top: 32px;
  @include font(16px, $black-color, $fw-bold, 24px);
  display: flex;
  gap: 16px;
}

@media (max-width: 1100px) {
  .minWidthForJob {
    min-width: 260px;
  }
}

@media (max-width: 900px) {
  .twoButtonContainer {
    //flex-wrap: wrap;
    justify-content: center;
    max-width: 335px;
    gap: 8px;

    button + button {
      margin-left: 0 !important;
    }
  }
}

@media (max-width: 780px) {
  .container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .workshopInfo {
    flex-direction: row;
    justify-content: space-between;
    min-width: 100%;
    gap: 20px;

    &::before {
      display: none;
    }

    .leftBlock {
      justify-content: space-between;
    }
    .rightBlock {
      justify-content: space-between;
    }
  }
}

@media (max-width: 680px) {
  .workshopInfo {
    flex-direction: column;
    justify-content: space-between;
    min-width: 100%;
    gap: 20px;
    align-items: center;

    .leftBlock {
      justify-content: space-between;
      .pickUp {
        margin-left: 0;
      }
    }
    .rightBlock {
      justify-content: space-between;
    }
  }
}

@media (max-width: 520px) {
  .container {
    display: flex;
    //max-width: 1136px;
    padding: 16px;
    justify-content: space-between;
    margin: 0;
  }
  .workshopInfo {
    margin-left: 0;
  }
  .rightBlock {
    //width: 350px;
  }
  .topLeftWrapper {
    flex-wrap: wrap;
    justify-content: center;
  }
}
