.contentContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  .formWrapper {
  //flex-basis: 580px;

}
}
