@import '../../../Theme/theme';


.container {
  //padding: 16px;
}

.carousel{
    display: flex;
}
