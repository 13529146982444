@import '../../../../../Theme/theme';

.contentWrapper {
  h2 {
    margin-top: 22px;
    font-size: 30px;
    color: #003356;
  }
}

ol {
  @include font(16px, #003356, $landing-text, 24px);
}

.contentWrapper {
  max-width: 963px;
  margin: 0 auto;
  margin-top: 60px;
  padding: 0 24px;
  p {
    @include font(16px, #003356, $fw-regular, 24px);
  }

  .listWrapper {
    @include font(16px, #003356, $fw-regular, 24px);
  }

  h3 {
    margin-top: 28px;
    @include font(24px, #003356, $fw-regular, 32px);
  }
}
