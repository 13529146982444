$primary-color: #5B8DB4;
$primary-light: #7AB1DB;
$primary-dark: #026BB5;
$font-blue:#A2CEEF;
$blue-light:#AEDBFD;

$background: #D7E4F3;
$background-light:#EFF8FF;
$black-color: #000000;
$font-color: #595959;
$devider-grey: rgba(0, 0, 0, 0.45);
$header-color: rgba(0, 0, 0, 0.85);
$side-menu-font-color: #8C8C8C;
$white: #ffffff;
$alert-color: #FFE58F;
$gray: rgba(196, 196, 196, 0.2);
$border-color: #F0F0F0;
$btn-text-color: #0F2F48; 
$neutral: #D9D9D9;
$landing-text: #003356;


$fw-thin: 100;
$fw-light: 300;
$fw-regular: 400;
$fw-bold: 700;
$fw-black: 900;




