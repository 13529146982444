@import '../../../../../../Theme/theme';

.logBookWrapper {
  width: 100%;
}

.itemWrapper {
  //margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //margin-left: 24px;
}

.deleteIcon {
  color: $primary-color;
  cursor: pointer;
}

.emptyIcon {
  @include font(14px, $header-color, $fw-regular, 22px);
  //min-width: 65px;
}

.deliteIcon:hover {
  color: $primary-light;
  margin-left: auto;
}

.demoLoadmoreList {
  width: 100%;
}

.serviceName {
  @include font(14px, rgba(0, 0, 0, 0.45), $fw-regular, 22px);

  .price {
    @include font(14px, $black-color, $fw-regular, 22px);
    margin-left: 12px;
  }
}
