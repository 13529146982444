@import "./Theme/_fonts.scss";
body {
 margin: 0;
 font-family: LatoRegular, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
