@import '../../Theme/theme';

.modalHeader {
    display: flex;
    flex-direction: row;
    img {
        margin-right: 14px;
    }
    .textTitle{
        padding-right: 10px;
        display: flex;
        align-items: center;
        span {
            @include font($size: 16px, $color: $black-color, $weight: 700, $height: 24px )
        }
    }
}

.buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    margin-bottom: 24px;
}

@media (max-width: 570px) {
    .modal{
        //overflow-y: scroll;
        // .stepsInfo{
        //     max-height: 250px;
        //     overflow-y: scroll;
        // }
    }
  }