@import '../../Theme/theme';

.button {
  height: 40px;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 40px;
  border: none;
  @include font(16px, $btn-text-color, 400, 24px);
  background: $white;
}
//for alert
.alertButtons {
  @include font(14px, $btn-text-color, 400, 22px);
}

.cancelButton {
  border: 1px solid #F0F0F0;
}


.sm {
  width: 172px;
}

.md {
  width: 300px;
}

.lg {
  width: 360px;
}

.max {
  width: 100%;
}

.button + .button {
  margin-left: 14px;
}

.button:active:not(.disabled) {
  transform: scale(.96);
}

.button:hover:not(.disabled) {
  color: $primary-color;
}

.saveButton {
  //background: linear-gradient(to right, $primary-light, $blue-light);
  background: #026BB5;
  border-radius: 40px;
  color: $white;
  box-shadow: 0px 7px 4px rgba(0, 0, 0, 0.043);
}
.saveButton:hover:not(.disabled) {
  background: $primary-color;
  color: $white;
}

.saveDark {
  background: $primary-dark;
  color: $white;
}

.decline {
  border: 1px solid #F5222D;
}

.withoutBackground {
  border: 1px solid $font-blue;
}

.disabled {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
}

@media screen and (max-width: 330px) {
  .saveButton:not(.fixWidth) {
    width: 120px;
  }
}
