@import '../../../Theme/theme';

.container {
  .buttonsWrapper {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 16px;

    div[class*="ant-upload-list-item "] {
      max-width: 150px;
    }
  }
}
