// @import '../../../Theme/theme';

.checkbox {
  margin: 8px 0px 0px 0px !important;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  padding-bottom: 8px;
  & > span:first-child {
    margin-bottom: 8px;
  }
}



.checkboxInspect {
  margin: 6px 0px 0px 0px !important;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  padding-top: 11px;
  padding-bottom: 16px;
  width: 100%;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.notDisable:not(#notExist) {
  color: rgba(0, 0, 0, 0.85);
}
.editIcon {
  margin-top: 16px;
  cursor: pointer;
}
.withInput > span:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  //margin-top: 45px;
  & > input {
    margin-bottom: 8px;
    margin-top: 8px;
    min-width: 160px;
    height: 54px;
  }
}

.withInput > span:nth-child(1) {
  align-self: flex-start;
}

/* @media (max-width: 380px) {
  .withInput > span:nth-child(2) {
    & > input {

    }
  }
}
 */