@import '../../Theme/theme';
@import '../../Theme/fonts';

.loginForm {
  margin: 0 auto;
  width: 100%;
}
.loginFormForgot {
  float: right;
}
.antColRtl .loginFormForgot {
  float: left;
}
.loginFormButton {
  width: 100%;
  background: linear-gradient(to right, $primary-light, $blue-light);
  margin-top: 66px;
  border: none;
}

.input {
  border-radius: 6px;
}

.link {
  text-decoration: underline;
  cursor: pointer;
  @include font($color: $primary-light);
}

.phoneInput {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.checkBoxText {
  @include font($color: rgba(0, 0, 0, 0.85));
  margin-right: 10px;
  margin-top: 12px;
}

.checkBoxText:not(#notExistId) {
  margin-left: 0;
}

.tooltipIcon {
  margin-left: 8px;
  color: $devider-grey;
}

.error {
  color: #ff4d4f;
  position: absolute;
  width: 285px;
  left: -4px;
  bottom: -1px;
}
