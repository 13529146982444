@import '../../../../../../Theme/theme';

.container {
  padding-bottom: 16px;
  max-height: 400px;
  .header {
    @include font(24px, $primary-color, 500, 32px);
    margin-top: 16px;
    //height: 50px;
    //overflow-y: scroll;
  }

  .description {
    @include font(14px, $black-color, 700, 22px);
    margin-bottom: 10px;
  }

  .subDescription {
    @include font(14px, $devider-grey, 500, 22px);
  }
 
}
.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  margin-bottom: 16px;
}

@media (max-width: 570px) {
  .mainInfo{
    max-height: 250px;
    overflow-y: scroll;
  }
}