@import '../../../../Theme/theme';

.container {
  background-color: white;
  height: max-content;
  margin-top: 80px;
  //padding-bottom: 100px;

  .contentWrapper {
    height: max-content;
    margin: 0 auto;
    overflow: hidden;
    z-index: 10;
    .titleBox{
      margin-bottom: 40px ;
      h2 {
        @include fontMulish(40px, #262626, 900, 48px);
        margin: 0 0 4px 0;
        text-align: center;
      }
      h4{
        @include fontMulishRegular(20px, rgba(0, 0, 0, 0.85), 600, 120%);
        text-align: center;
      }
    }
    .subTitle {
      max-width: 400px;
      text-align: center;
      margin: 0 auto;
      @include font(18px, $devider-grey, $fw-regular, 28px);
      text-align: center;
    }
  }

  .carousel {
    max-width: 1400px;
    height: 492px;
    margin: 0 auto;
    //margin-bottom: 20px ;
    //min-height: 560px;
    //max-width: 1440px;
    //margin: 0 auto;
    //overflow: hidden;
  
    div[class='slick-list'] {
      padding-top: 20px;
      padding-bottom: 20px;
      //overflow: visible;
    }
    div[class='slick-list'] + ul {
      bottom: -32px;
    }
  }

  .counterPages{
    visibility: hidden;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    @include font(14px, rgba(0, 0, 0, 0.45), 400, 22px);
    align-items: flex-end;
    span{
      @include font(16px, #026BB5, 700, 24px);
    }
  }
}



.arrow:not(#not-exist-id) {
  background: #E6F7FF;
  border-radius: 50%;
  width: 62px;
  height: 62px;
  z-index: 1;
  
  top: 40%;
}

.arrow:not(#not-exist-id)::before {
  content: '';
  position: absolute;
  top: 40%;
  right: 40%;
  z-index: 2;
  color: #026BB5;
  width: 12px;
  height: 12px;
  border-right: 2px solid currentcolor;
  border-bottom: 2px solid currentcolor;
  transform: rotate(135deg);
  overflow: visible;
}

.arrowRight:not(#not-exist-id)::before {
  content: '';
  transform: rotate(-45deg);
}
@media screen and (max-width: 1575px) {
  .container{
    .carousel{
      max-width: 1260px;
    }
  }
}
@media screen and (max-width: 1350px) {
  .container{
    padding-bottom: 0;
    .counterPages{
      visibility: visible;
    }
  }
  .arrow{
    display: none !important; 
  }
  .arrowRight{
    display: none !important;
  }
}
@media screen and (max-width: 650px){
  .carousel{
    max-height: 274px;
  }
}
@media screen and (max-width: 480px) {
  .container{
    margin-top: 42px;
    .contentWrapper{
      .titleBox{
        margin-bottom: 12px ;
        h2{
          font-size: 26px;
          line-height: 120%;
        }
        h4{
          font-size: 12px;
          line-height: 22px;
        }
      }
    }
  }
}

/* .carousel::before {
  content: '<';
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: -25px;
  background-color: crimson;
  cursor: pointer;

}

.carousel::after {
  content: '>';
  width: 24px;
  height: 24px;
  position: absolute;
  background-color: crimson;
  cursor: pointer;
  
} */
