@import '../../Theme/theme';

.tabs {
  &  div[role='tablist']::before {
    width: 138px;
  }
  
}

.date {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0px;
  top: 10px;
  margin-right: 16px;
  margin-left: 16px;
  flex-wrap: wrap;
  gap: 10px;

  .filterText {
    color: #595959;
    margin: 5px 5px 0 0;
    word-wrap: break-word;
  }
}

.datePicker {
  height: 32px;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 6px;
}

@media (max-width: 835px) {
  .date {
    p {
      word-wrap: normal;
      margin: 0px 0px 0px 10px;
    }
  }
}

@media (max-width: 570px) {
  .date {
    position: unset;
  }
}
