@import '../../../../../../Theme/theme';

.container {
  display: flex;
  justify-content: center; 

  .quotesIcon {
    align-self: flex-start;
    margin-top: 65px;
  }

  .reviewWrapper {
    max-width: 369px;
    min-height: 250px;
    position: relative;
    background: #FFFFFF;
    padding: 20px 28px;
    border: 1px solid #A2CEEF;
    box-shadow: 0px 11px 24px rgba(55, 78, 94, 0.11);
    border-radius: 16px;
    .header {
      @include font(18px, rgba(0, 0, 0, 0.85), $fw-regular, 28px);
      font-weight: 700;
      margin: 0;
    }

    .name {
      @include font(18px, #026BB5, $fw-regular, 28px);
      font-weight: 500;
      margin: 0 auto;
    }

    .text {
      @include font(16px, rgba(0, 0, 0, 0.85), $fw-regular, 24px);
      height: 96px;
      font-weight: 400;
      opacity: 0.7;
      margin-top: 16px;
      margin-bottom: 6px;
    }

    .rateContainer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      min-width: 200px;
      //position: absolute;
      //top: 100%;
      //transform: translateY(-100%);

      img {
        align-self: center;
        margin-top: 5px;
      }
      .rate {
        margin-right: 28px;
        //min-width: 138px;
      }
      .google {
        @include font(16px, rgba(0, 0, 0, 0.85), $fw-regular, 24px);
        font-weight: 400;
        margin-left: 8px;
        padding-top: 3px;
      }
    }
  }
}

.arrow:not(#not-exist-id) {
  background: $white;
  border-radius: 50%;
  width: 62px;
  height: 62px;
  z-index: 1;
  top: 40%;
}

.arrow:not(#not-exist-id)::before {
  content: '';
  position: absolute;
  top: 40%;
  right: 40%;
  z-index: 2;
  color: #026BB5;
  width: 12px;
  height: 12px;
  border-right: 2px solid currentcolor;
  border-bottom: 2px solid currentcolor;
  transform: rotate(135deg);
}

.arrowRight:not(#not-exist-id)::before {
  content: '';
  transform: rotate(-45deg);
}

@media screen and (max-width: 480px) {
  .container {
    .quotesIcon {
      display: none !important;
    }
    .reviewWrapper{
      max-width: 327px;
      .header{
        font-weight: 600 !important;
        font-size: 18px !important;
        line-height: 28px !important;
      }
      .text{
        height: 120px;
      }
      
      .rateContainer{
        .rate{
          margin-right: 20px !important;
        }
      }
    }
  }
}
