@import '../../../../Theme/theme';

.item {
  max-width: 442px;
  label {
    color: #595959 !important;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    white-space: pre-line;
  }
  .gears {
    label {
      height: auto;
    }
  }

  .bottomLabel {
    //position: relative;
    //left: 36px;
    //max-width: 386px;
    display: block;
    margin-left: 22px;
    margin-bottom: 24px;
    text-align: end;
    font-size: 12px;
    line-height: 14px;
    color: #5b8db4;
  }
}

.input {
  max-width: 100%;
}

@media screen and (max-width: 575px) {
  .input {
    max-width: 100%;
  }
}
