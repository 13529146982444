.noteAnimate-exit-active {
    animation: noteAnimate 800ms ease-out forwards;
  }
   
  .noteAnimate-enter-active {
    animation: noteAnimate 800ms ease-in alternate-reverse;
  }
   
  @keyframes noteAnimate {
    0% {
      transform: translateX(0);
    }
    30% {
      opacity: .7;
      transform: translateX(30px);
    }
    100% {
      opacity: 0;
      transform: translateX(-200px);
    }
  }