@import './Theme/theme';

h1,
h2,
h3,
h4,
h5 {
  color: $header-color;
}

body {
  font-family: LatoRegular, sans-serif;
}

.ant-checkbox-wrapper:not(.green) .ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-light;
  border-color: $primary-light;
}

.ant-checkbox-wrapper.green .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #389E0D;
  border-color: #389E0D;
}



//.ant-menu > .ant-menu-item:hover,
//.ant-menu > .ant-menu-submenu:hover,
// .ant-menu > .ant-menu-item-active,
// .ant-menu> .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  border-radius: 0px 20px 20px 0px;
  background: $background-light;
  box-shadow: 0px 11px 12px rgba(55, 78, 94, 0.08);
  color: #595959;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}
.ant-btn-primary {
  background: $primary-color;
}
.ant-btn-primary:hover {
  background: $primary-color;
}
.ant-btn-primary:active {
  background: $primary-color;
}

.ant-tree .ant-tree-treenode > span {
  background-color: white;
}

.ant-row {
  margin-bottom: 16px;
}

.ant-list-header,
.ant-list-footer {
  padding-top: 0;
  padding-bottom: 0;
}

.ant-steps-item-container {
  max-width: 80px;
  margin-bottom: 30px;
}

@media screen and (max-width: 575px) {
}

.ant-input {
  border-radius: 6px;
}

.ant-modal-header {
  background-color: $background-light;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  border-radius: 0px 6px 6px 0;
  border-left: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px;
  border-color: #A2CEEF;
}
 
.ant-select-selection-placeholder{
  color: rgba(0, 0, 0, 0.85);;
}
.ant-input-affix-wrapper {
  border-radius: 6px;
  height: 44px;
}

.ant-btn {
  border: 1px solid #a2ceef;
}
///Steper
.ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: $primary-color;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: $primary-color;
}

.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: $primary-color;
}
// .ant-steps-vertical>.ant-steps-item:not(:last-child)>.ant-steps-item-container>.ant-steps-item-tail {
//   display: none;
// }

// .ant-steps-item-content, .ant-steps-item-icon {
//   display: inline-block;
//   vertical-align: top;
// }

// .ant-steps-vertical{
//   flex-direction: row;
// }

.ant-steps-item-active {
  font-weight: bold;
}
///

// remove padding from modal
.ant-modal-body {
  padding-top: 0px;
  padding-bottom: 0px;
}
///

/// Tree node selected and hover
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #fff;
}

.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #fff;
}
////

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  background-color: white;
}

// .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
//   .ant-select-selector {
//   // height: 44px;
// }
/// margin right - left for tab-panel workshop jobs
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 16px 16px 16px;
}

.ant-tabs-ink-bar {
  background: $primary-color;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary-color;
}

.ant-tabs-tab:hover {
  color: $primary-color;
}

thead[class*='ant-table-thead'] th {
  background-color: $background-light;
}

////radio-button color
.ant-radio-checked .ant-radio-inner {
  border-color: #026BB5;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #026BB5;
}

.ant-radio:hover .ant-radio-inner {
  border-color: $primary-color;
}
.ant-radio-button-wrapper:before{
  display: none !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  background: #026BB5 !important;
  color: white !important;
}
////data picker placeholder

.ant-picker-large .ant-picker-input > input[placeholder*='Select date'] {
  margin-left: 20px;
}

.ant-modal-content {
  overflow: auto;
  border-radius: 6px;
  
}

////slider admin/clients/profile
.ant-carousel .slick-dots li {
  background-color: $primary-color;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: $primary-color;
}

/* .ant-carousel .slick-dots-bottom {
  bottom: -12px;
} */
.customSelect {
  border-radius: 0px 20px 20px 0px;
  background-color: #e6f7ff;
  box-shadow: 0px 11px 12px rgb(55 78 94 / 8%);
  color: #595959;
}

//burger button Main Layout
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: #026BB5;
  font-size: 28px;
  background: transparent;
}

.ant-layout-sider-zero-width-trigger {
  top: -5px;
  right: -50px;
}

.ant-segmented-item-label{
  padding: 0 8px;
  //font-size: 12px;
}

.ant-segmented-item-selected{
  font-size: 14px;
}

///hover tree admin services
.ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
  background: white;
}

//padding for stepper address step
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item {
  padding-left: 10px;
}

// .ant-form-item-control-input-content{
//   .ant-select{
//     .ant-select-selector{
//       height: 44px !important;
//     }
//   }
// }

//label to input in account info customer
// .ant-select-selector{
//   height: 44px !important;
// }
@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0 0 0px;
    margin-bottom: 16px !important;
    
  }
  .ant-modal-body {
    padding: 0 16px;
  }
  .ant-steps-item-container {
    margin-bottom: 16px;
  }
  .ant-modal-content {
    margin: 0 16px;
  }
}
