@import '../../../Theme/theme';


.contain {
  max-width: 476px;
  height: 680px;
  border-radius: 6px;
  background-color: $white;
  margin: 70px auto 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 56px;
  .header {
    @include fontMulishBold(40px, $font-blue, 800, 120%);
    margin-bottom: 40px;
  }

  .button {
    width: 100%;
    display: block;
    margin-bottom: 20px;

    .btnText {
      margin-left: 12px;
    }
  }

  .haveAccountText {
    text-align: center;
  }

  .alert {
    border: 2px solid #ffccc7;
    height: 40px;
    border-radius: 6px;
    margin-top: 20px;
    text-align: center;
    div{
      color: red;
    }
  }
}
@media (max-width: 760px) {
  .contain {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10%;
    width: 100vw;
    height: 100vh;
    margin: 0;
    background-color: $white;
  }
}
