@import '../../../../Theme/theme';

.contentContainer {
  display: flex;
  justify-content: center;
  gap: 60px;
  //margin-left: 104px;
  // .leftWrapper{
  //   width: 550px;
  // }
}
.tabbyPromo{
  display: none;
}
div[class='ant-modal-body']{
  padding: 0 14px ;
}

.workshopModal{
  padding: 0
}

.leftWrapper{
  h2{
    @include fontMulish(24px, rgba(0, 0, 0, 0.85), 900, 32px);
    margin-top: 0 !important;
  }
}

.pagination{
  display: flex;
  justify-content: center;
}

.sortContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 24px;

  .priceSortTitle {
    @include font($color: $font-color);
  }

  .sortByPartnerTitle {
    @include font($color: $font-color);
    margin-left: 8px;
  }

  .selectContainer {
    margin-right: 30px;
    .sity{
      padding: 0 8px;
    }
  }
}

.workshop {
  width: 100%;
}

.buttons {
  //width: 95%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

@media (max-width: 1310px) {
  .tabbyPromo{
    display: flex;
    justify-content: center;
    gap: 8px;
    //margin-top: 10px;
    margin-bottom: 20px;
    p{
      //margin: auto;
      margin: auto  0;
      @include font(16px, rgba(0, 0, 0, 0.85), 700, 24px);
    }
    img{
      width: 78px;
      height: 32px  ;
    }
  }
  .contentContainer {
    margin-left: 0px;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .hide {
    display: none;
  }
}

@media (max-width: 835px) {
  .container {
    flex-direction: column;
  }

  .contentContainer {
    flex-wrap: wrap;
  }
  .buttons {
    margin-top: 10px;
  }
}

@media (max-width: 735px) {
  .sortContainer {
    width: 100vw;
    overflow-x: scroll;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 16px;
    margin-left: 0 !important;
    margin-bottom: 24px;
    .sityContainer{
      width: calc(100% - 32px);
    }
  
    // .priceSortTitle {
    //   @include font($color: $font-color);
    // }
  
    // .sortByPartnerTitle {
    //   @include font($color: $font-color);
    //   margin-left: 8px;
    // }
  
    // .selectContainer {
    //   margin-right: 30px;
    //   .sity{
    //     padding: 0 8px;
    //   }
    // }
  }
}

@media (max-width: 620px) {
  .leftWrapper{
    width: 100%;
  }
  .contentContainer{
    // .leftWrapper{
    //   margin: 0 16px;
    // }
  }
}

@media (max-width: 532px) {
  // div[class='ant-modal-body']{
  //   padding: 0;
  // }
  div[class='ant-modal-content']{
    margin: 0 !important;
  }
  h2{
    margin-left: 16px;
  }
  .sortContainer {
    margin-left: 16px;
    .checkbox {
      margin-top: 20px;
    }
  }
}
