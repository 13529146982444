@font-face {
  font-family: 'LatoBlack';
  src: local('LatoBlack'), url(./../Assets/Fonts/Lato-Black.ttf) format('truetype');
}
  
@font-face {
  font-family: 'LatoBold';
  src: local('LatoBold'), url(./../Assets/Fonts/Lato-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'LatoRegular';
  src: local('LatoRegular'), url(./../Assets/Fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'LatoLight';
  src: local('LatoLight'), url(./../Assets/Fonts/Lato-Light.ttf) format('truetype');
}


@font-face {
  font-family: 'LatoThin';
  src: local('LatoThin'), url(./../Assets/Fonts/Lato-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoRegular';
  src: local('RobotoRegular'), url(./../Assets/Fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'MulishRegular';
  src: local('MulishRegular'), url(./../Assets/Fonts/Mulish-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'MulishBlack';
  src: local('MulishBlack'), url(./../Assets/Fonts/Mulish-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'MulishBold';
  src: local('MulishBold'), url(./../Assets/Fonts/Mulish-Bold.ttf) format('truetype');
}