@import '../../Theme/theme';

.contentContainer {
  flex-direction: column;
  gap: 24px;
}

.carlistContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.addHeight {
  height: calc(100vh - 174px);
}
.container {
  //margin-top: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  h2 {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    color: #5b8db4;
    font-size: 30px;
    line-height: 40px;
  }
  .bottom {
    width: 200px;
    position: absolute;
    right: 16px;
    bottom: 16px;
    // right: -24px;
    // bottom: -24px;
  }
}

.car {
  position: relative;
  box-shadow: 0px -7px 10px rgba(55, 78, 94, 0.02),
    0px 6px 20px rgba(55, 78, 94, 0.08);
  border-radius: 6px;
  align-items: center;
  margin: 0px;
  width: 550px;
  min-width: 250px;
  max-width: 550px;
  height: 232px;
  padding: 16px ;
  display: flex;
  flex-shrink: 2;
  img {
    height: 153px;
    width: 200px;
  }

  .clickable {
    cursor: pointer;
  }
}
.description {
  margin-left: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 55%;
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
}
.trash {
  position: absolute;
  top: 12px;
  right: 12px;
  color: #5b8db4;
}

.addCarButton {
  max-width: 300px;
  margin-left: auto;
  margin-bottom: 24px;
}

.buttonClass {
  margin: 0px;
  width: 288px;
}

@media (max-width: 650px) {
  .car {
    display: flex;
    flex-direction: column;
    height: auto;
    padding-bottom: 20px;
    align-items: center;
    text-align: center;
  }
  .description{
    width: 70%;
  }
  .car:nth-child(odd) {
    margin-left: 0px;
    // float: right;
  }
  .buttonClass {
    margin: 0px;
    //width: 170px;
    align-self: center;
  }
  .clickable {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 6px 10px;
    width: 100%;
    margin-bottom: 6px;
  }
  h3{
    margin-bottom: 0;
  }
  p{
    margin-bottom: 6px;
  }
  
}


@media (max-width: 650px) {
  .addCarButton {
    margin: 0 auto 24px auto;
  }
}
