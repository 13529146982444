@import '../../Theme/theme';

.headerContainer {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
  
  .filtersContainer {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;

    .dataPicker {
      border-radius: 6px;
      width: 150px;
    }

    .rangePicker {
      border: 1px solid #f0f0f0;
      border-radius: 6px;
      margin-left: 60px;
      width: 256px;
    }
  }
}

.location {
  display: flex;
  img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
  div {
    padding-top: 2px;
  }
}
