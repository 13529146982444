@import '../../../../../Theme/theme';

.container {
  .header {
    margin-top: 16px;
    margin-bottom: 28px;
    span {
      @include font($color: $devider-grey);
      & + span {
        @include font($color: $header-color, $weight: $fw-bold);
      }
    }
  }

  .wrapper {
    display: flex;

    .workshopImage {
      max-width: 160px;
      max-height: 160px;
      border-radius: 50%;
    }

    .rate {
      margin: 0 0 15px 8px;
    }

    .pulse {
      animation: stretch-bounce 0.5s ease-in-out;
    }

    .textArea {
      margin-left: 8px;
      min-height: 130px;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 8px;
  }
}

.star {
  animation: stretch-bounce 0.5s ease-in-out;
}

@keyframes stretch-bounce {
  0% {
    -webkit-transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.5);
  }
  50% {
    -webkit-transform: scale(0.9);
  }
  75% {
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

@media screen and (max-width: 440px) {
  .container {
    .rateWrapper {
      flex-wrap: wrap;
    }
  }
}

@media screen and (max-width: 500px) {
  .workshopImage {
    display: none;
  }
}
