@import '../../../Theme/theme';

.labelSpan {
  @include font(16px, $devider-grey, $fw-regular, 24px);
  margin-left: 17px;
  margin-bottom: 15px;
}

.topFormWrapper {
  max-width: 375px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.hide {
  display: none;
}

.inputContainer {
  display: flex;
  position: relative;
  min-width: 251px;
  justify-content: flex-end;

  div[role="alert"] {
    position: absolute;
    z-index: 1;
  }
}

.labelSuffixText {
  position: relative;
  width: 24px;
  padding-top: 5px;
  margin-left: 5px;
  @include font(12px)
}

.labelText {
  position: relative;
  padding-top: 5px;

  .tooltipText {
    color: $devider-grey;
    cursor: pointer;
    margin-left: 5px;

    &:hover + .hide {
      display: block;
    }
  }

  .tooltip {
    position: absolute;
    top: -1px;
    right: -455px;
    white-space: nowrap;
    @include font(14px, $white, $fw-regular, 22px);
    background-color: #205680;
    border-radius: 2px;
    padding: 6px 8px;

    &::before {
      position: absolute;
      content: '';
      left: -6px;
      top: 10px;
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-right: 7px solid #205680;
    }
  }
  .tooltip2 {
    position: absolute;
    top: -1px;
    right: -420px;
    white-space: nowrap;
    @include font(14px, $white, $fw-regular, 22px);
    background-color: #205680;
    border-radius: 2px;
    padding: 6px 8px;

    &::before {
      position: absolute;
      content: '';
      left: -6px;
      top: 10px;
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-right: 7px solid #205680;
    }
  }
}

.input {
  border-radius: 3px;
  max-width: 80px;
  margin-left: 10px;
}

@media screen and (max-width: 689px) {
  .labelText {
    .tooltip {
      top: -36px;
      right: -210px;

      &::before {
        left: 134px;
        top: 32px;
        border-top: 7px solid #205680;
        border-bottom: none;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
      }
    }
  }
}
