@import '../../../../../Theme/theme';

.cityList{
  display: flex;
  width: 100%;
  .segmented{
    @include font(12px,rgba(0, 0, 0, 0.45), 400, 20px);
    //margin: 0 16px;
  }
  // .selected{
  //   width: max-content;
  //   background:white;
  //   span{
  //     @include font(14px,rgba(0, 0, 0, 0.85), 500, 22px);
  //     margin: 3px 5px;
  //   }
  // }
  // .disabled{
  //   background: #EAEEF0;
  //   width: max-content;
  //   cursor: pointer;
  //   span{
  //     @include font(12px,rgba(0, 0, 0, 0.45), 400, 20px);
  //     margin: 3px 8px;
  //   }
  // }
}

@media (max-width: 735px) {
  .cityList{
    //margin: 0 16px;
    //margin-right: 16px;
  }
}