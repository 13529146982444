@import '../../../Theme/theme';

.title {
  @include font(16px, $primary-color, $fw-bold, $height: 24px);
  margin-bottom: 20px;
}

.checkboxContainer {
  max-width: 360px;

  .rowContainer {
    display: flex;
    justify-content: flex-start;
  }
}

.promoItem {
  margin-bottom: 16px;
}

.buttonContainer {
  margin-top: 12px;
}
