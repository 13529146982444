@import '../../../../Theme/theme';

.container {
  background-color: white;
  width: 100%;
  margin-top: 80px;
  //margin-bottom: 40px;
  h2 {
    margin: 0 0 56px 0;
    @include fontMulish(40px, #262626, 900, 48px);
    text-align: center;
  }

  .infoWrapper {
    max-width: 1156px;
    width: max-content;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 0 16px;

    .leftBlockWrapper {
      background: url(../../../../Assets/Landing/mockups-image2.svg) no-repeat;
      background-position: left;
      background-size: cover;
      //background-size: 765px;
      width: 708px;
      min-height: 532px;
    }

    .stepsWrapper {
      max-width: 360px;
      //margin-left: auto;
      padding: 16px 0;

      .stepper {
        padding: 0;
      }

      .stepper li {
        display: flex;
        list-style-type: none;
        text-align: left;
        margin-bottom: 32px;

        &:last-child .circle::after {
          height: 28px;
        }

        &:first-child .circle::before {
          content: ' ';
          position: absolute;
          width: 1px;
          height: 17px;
          background-color: #d9d9d9;
          top: -19px;
          right: 50%;
        }

        .circle {
          border-radius: 50%;
          position: relative;
          align-self: center;
          width: 52px;
          height: 52px;
          border: 1px solid #5b8db4;
          box-sizing: border-box;
          text-align: center;
          padding-top: 7px;
          background-color: white;
          @include font(24px, #d55672, $fw-regular, 32px);

          &::after {
            content: ' ';
            position: absolute;
            width: 1px;
            height: 60px;
            background-color: #d9d9d9;
            top: 50px;
            right: 50%;
          }
        }

        .descriptionWrapper {
          margin-left: 24px;
        }
      }

      .title {
        @include font(24px, $header-color, 500, 32px);
      }

      .description {
        color: $devider-grey;
        max-width: 244px;
      }
    }
  }
}

@media screen and (max-width: 1154px) {
    .container {
      .infoWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .leftBlockWrapper {
          position: relative;
          left: 48px;
          //background-position: 12px 0px;
          min-height: 520px;
        }

        .stepsWrapper {
          margin: 0 auto;
          padding: 0;
        }
      }
    }
  }

  @media screen and (max-width: 730px) {
    .container {
      .infoWrapper {

        .leftBlockWrapper {
          width: 620px;
          background-size: contain;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .container {
      margin: 0;
      padding: 32px 0;
      .infoWrapper {
        .leftBlockWrapper {
          width: 520px;
          min-height: 320px;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .container {
      h2 {
        margin: 0;
        font-size: 26px !important;
        line-height: 120% !important;
        margin-bottom: 34px;  
      }
      .infoWrapper {
        .leftBlockWrapper {
          width: 420px;
          left: 20px;
        }
        .stepsWrapper{
          margin: 0;
        }
      }
    }
  }

  @media screen and (max-width: 440px) {
    .container {
      h2{
        margin: 0;
      }
      .infoWrapper {
        .stepsWrapper {
          .stepper li {
            gap: 24px;
            margin-bottom: 16px;
            .descriptionWrapper {
              margin-left: 16px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 440px) {
    .container {
      .infoWrapper {
        .stepsWrapper{
          .title{
            font-size: 18px !important;
            line-height: 28px !important;
          }
          .description{
            font-size: 12px !important;
            line-height: 20px !important;
          }
        }
        .leftBlockWrapper {
          min-width: 320px;
          width: 100%;
          min-height: 237px;
          height: 300px;
        }
      }
    }
  }


  @media screen and (max-width: 340px) {
    .container {
      .infoWrapper {
        .leftBlockWrapper {
          left: 0px;
        }
      }
    }
  }
