@import '../../../../../../Theme/theme';

.marginRight:not(sds) {
  margin-right: 20px;
}

.partnersWrapper{
  display: flex;
  justify-content: center;
  overflow: visible;
  .container {
    background: $white;
    box-shadow: 0px 11px 12px rgba(55, 78, 94, 0.08);
    border-radius: 16px;
    width: 300px;
    z-index: 15;
    height: 472px;
    //padding-bottom: 32px;
    transition: transform 700ms;
    cursor: pointer;
    &:hover {
      transform: scale(1.08);
    }
  
    .workshopImage{
      border-radius: 16px 16px 0 0;
      margin-bottom: 16px;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  
    .infoWrapper {
      padding-left:20px;
  
      .rate{
        margin: 0 8px;
      }
      .workshop {
        @include fontBlack(18px, rgba(0, 0, 0, 0.85), 900, 28px);
      }
  
      .description {
        @include font($color: rgba(0, 0, 0, 0.85));
        margin-bottom: 15px;
        margin-top: 8px;
        height: 65px;
        overflow: hidden;
      }

      p{
        @include font(14px, $landing-text, 400, 22px);
      }

      .google{
        width: 16px;
        margin: 0 5px;
      }

      .rateBox{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        @include font(14px,#026BB5, 400, 16px);
        li{
          width: 13.45px;
          svg{
            height: 13.45px;
            width: 13.45px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
 .partnersWrapper{  
  .container {
    max-width: 168px;
    height: 214px;
    width: 168px;
    margin: 0px auto;
    border-radius: 4px;

    .infoWrapper {
      padding-left: 12px;
      height: 37px;
      .workshop{
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        height: 21px;
        width: 144px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .description{
        display: none;
      }

      .rate{
        margin: 0 2.5px;
      }

      .google{
        width: 8px;
        margin: 0 2px;
      }

      .rateBox{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        @include font(8px,#026BB5, 400, 100%);
        li{
          margin: 0 2.5px;          
          width: 6.61px;
          svg{
            height: 6.61px;
            width: 6.61px;
          }
        }
      }
      .workshop {
        margin-bottom: 0;
      }
    }

    .workshopImage {
      width: 168px !important;
      height: 168px !important;
      object-fit: cover;
      object-position: center;
      margin-bottom: 4px;
      border-radius: 4px 4px 0 0;
    }

    &:hover {
      transform: scale(1);
    }
  }
 }
}
