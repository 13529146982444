@import '../../../Theme/theme';

.alertBody {
  padding-top: 32px;
  @include font(16px, $black-color, $fw-bold, 24px);
  display: flex;
  gap: 16px;
}
.subContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 380px;
  margin-top: 16px;
  margin-bottom: 16px;
}