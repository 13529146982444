@import '../../../Theme/theme';

.input {
  border-radius: 3px;
  margin: 6px;
  max-width: 100px;
}

.formItem {
  margin: 11px 0;
  div:nth-child(2) {
    position: absolute;
    top: 25px;
  }
}

.days{
  display: flex;
  flex-direction: column;
}


.button  {
  margin-left: 12px;
  height: 42px;
  border: none;
}

.saveButton {
  background: linear-gradient(to right, $primary-light, $blue-light);
  width: 180px;
}


.label {
  margin: 0 auto;
  text-align: center !important;
  white-space:nowrap;
  @include font(14px, $black-color, $fw-bold, 22px)
}

.checkBox {
  margin: 13px 0!important;
}



@media screen and (max-width: 345px) {
  .label {
    font-size: 13px;
  }
  
}