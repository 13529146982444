.checkAnimate-exit-active {
  animation: checkAnimate 500ms ease-in;
}

.checkAnimate-enter-active {
  animation: checkAnimate 500ms ease-in alternate-reverse;
}

@keyframes checkAnimate {
  0% {
    opacity: 0.2;
    transform: scale(0);
  }
  30% {
    opacity: 0.7;
    transform: scale(2);
  }
  60% {
    opacity: 0.8;
    transform: scale(3);
  }
  80% {
    opacity: 0.9;
    transform: scale(2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
