@import '../../../../../../Theme/theme';

.card {
  img {
    // margin: 0 100px;
  }
  text-align: center;
  align-items: center;
  padding: 20px 15px;
  background: $white;
  box-shadow: 0px 11px 24px rgba(55, 78, 94, 0.11);
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 16px;
  display: flex;
  flex-basis: calc(100% / 2 - 12px);
  min-width: 163px;
  max-width: 100%;
  //height: 100px;
  position: relative;
  transition: 0.6s;
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }

  .image{
    height: 64px;
    width: 64px;
  }
  h3{
    @include font(24px, rgba(0, 0, 0, 0.85), 500, 32px);
    margin-bottom: 0;
  }
  .subtitle {
    text-align: center;
    @include font(14px, rgba(0, 0, 0, 0.45), 500, 22px);
    //padding: 0 12px;
  }

  .radio {
    position: absolute;
    top: 15px;
    left: 15px;
  }

  .icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .hide {
    display: none;
  }
}

.column {
  flex-direction: column;
}

.disable {
  opacity: 0.4;
  cursor: auto;
  &:hover {
    transform: scale(1);
  }
}

.selectedBorder {
  border: 1px solid #A2CEEF;
  &:hover {
    //transform: scale(1);
    //cursor: auto;
  }
}

.selectedBorder:not(.hideDot)::after {
  content: '';
  width: 10px;
  height: 10px;
  background-color: #CE0431;
  position: absolute;
  right: 8px;
  top: 8px;
  border-radius: 100%;
}

@media screen and (max-width: 1310px) {
  .subtitle{
    display: none;
  }
}

@media screen and (max-width: 570px) {
  .card {
    box-shadow: 0px 0px 4px rgba(30, 72, 102, 0.03), 0px 3px 8px rgba(30, 72, 102, 0.1);
    flex-direction: row;
    padding: 4px;
    .image{
      fill: #5B8DB4;
      height: 52px !important;
      width: 52px !important;
    }
    h3{
      margin-left: 4px;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
    }
  }
  
}

@media screen and (max-width: 540px) {
  .card {
    flex-basis: calc(100% / 2 - 8px);
  }
}

@media screen and (max-width: 370px) {
  .card {
    flex-basis: 90%;
  }
}
