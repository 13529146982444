@import '../../../../Theme/theme';

.container {
  width: 100%;
  //background: url(../../../../Assets/Landing/testimonials-back.svg) no-repeat;
  background-position: bottom right;
  background-color: white;
  padding: 40px 16px 74px 16px;

  h2 {
    @include fontMulish(40px, #262626, 900, 48px);
    text-align: center;
    margin: 0 0 56px 0;
  }

  .carousel {
    max-width: 1600px;
    margin: 0 auto;
    min-height: 298px;

    div[class='slick-list'] {
      padding-bottom: 30px;
    }

    div[class='slick-list'] + ul {
      bottom: -32px;
    }
  }

  .counterPages{
    visibility: hidden;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    @include font(14px, rgba(0, 0, 0, 0.45), 400, 22px);
    align-items: flex-end;
    span{
      @include font(16px, #026BB5, 700, 24px);
    }
  }
}
.arrow:not(#not-exist-id) {
  background: #E6F7FF;;
  border-radius: 50%;
  width: 62px;
  height: 62px;
  z-index: 1;
  top: 40%;
}

.arrow:not(#not-exist-id)::before {
  content: '';
  position: absolute;
  top: 39%;
  right: 38%;
  z-index: 2;
  color: #026BB5;
  width: 15px;
  height: 15px;
  border-right: 2px solid currentcolor;
  border-bottom: 2px solid currentcolor;
  transform: rotate(135deg);
}

.arrowRight:not(#not-exist-id)::before {
  content: '';
  transform: rotate(-45deg);
}
@media screen and (max-width: 1750px) {
  .container{
    .carousel{
      max-width: 1260px;
    }
  }
} 
@media screen and (max-width: 1350px) {
  .container{
    .counterPages{
      visibility: visible;
    }
  }
  .arrow{
    display: none !important;
  }
  .arrowRight{
    display: none !important;
  }
}

@media screen and (max-width: 580px) {
  .container{
    padding-bottom: 0;
    background-image: none;
  }
  .arrow{
    display: none !important;
  }
  .arrowRight{
    display: none !important;
  }
}

@media screen and (max-width: 480px) {
  .container{
    h2{
      font-size: 26px !important;
      line-height: 120% !important;
      margin-bottom: 24px;
    }
  }
}