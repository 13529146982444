@import '../../Theme/theme';

.container {
  width: 100%;
  margin: 0 auto;

  h1 {
    @include font(38px, $landing-text, $fw-bold, 46px);
  }

  h2 {
    @include font(38px, $landing-text, $fw-bold, 46px);
  }

  ol {
    padding-left: 24px;
  }
}

// @media screen and (max-width: 520px) {
//   .container {
//     h1 {
//       @include font(30px, $landing-text, $fw-bold, 40px);
//     }
//     h2 {
//       @include font(24px, $landing-text, $fw-bold, 32px);
//     }
//   }
// }
