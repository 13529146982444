@import '../../../../../../Theme/theme';

.container {
  //padding: 0 20px 0 0;
  max-width: none;
  margin-top: 24px;
}
.header {
  @include fontMulish(18px, rgba(0, 0, 0, 0.85), 900, 28px);
  // margin-bottom: 4px;
}
.deliveryContainer{
  display: flex;
  flex-direction: column;
  .deliveryItem{
    margin-top: 8px;
    @include font(14px, rgba(0, 0, 0, 0.85), 400, 22px);
    .item{
      display: flex;
      span{
        color: #026BB5;
        text-decoration: underline;
        //opacity: 1;
      }
      p{
        margin: 0;
        margin-left: 12px;
        @include font(14px,  rgba(0, 0, 0, 0.45), 400, 22px);
        //opacity: 0.45;
      }
    }
  }
}

.checkboxContainer {
  margin-left: 8px;
  .header {
    @include font($color: $black-color, $weight: $fw-bold);
    margin-bottom: 4px;
  }
  .subTitle {
    @include font($color: $devider-grey);
  }

  .bottomContainer {
    @include font($color: $black-color, $weight: $fw-bold);
    display: flex;
    gap: 4px;

    span {
      margin-top: 4px;
      align-self: flex-end;
    }
  }

}
@media screen and(max-width: 520px) {
  .deliveryContainer{
    .deliveryItem{
      .item{
        flex-direction: column;
        p{
          margin-left: 0;
        }
      }
    }
  }
}
@media screen and(max-width: 420px) {
  .container {
    max-width: 330px;
  }
}