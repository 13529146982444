@import "../../Theme/theme";

.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    max-height: 784px;
    
    .contentWrapper {
        max-width: 470px;
        text-align: center;
    }
    h1 {
       @include font(38px, $primary-color, $fw-bold, 46px);
       text-align: center;
    }

    .subTitle {
        @include font($color: $devider-grey);
        padding: 0 55px;
        text-align: center;
    }
}