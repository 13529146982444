@import '../../../../Theme/theme';

@keyframes pulse {
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    opacity: 1;
  }
}

.modalHeader{
  @include font(22px,rgba(0, 0, 0, 0.85),700,120%);
}
.modalBody{
  margin: 0 18px;
  .main{
    .modalTitle{
      display: flex;
      align-items: center;
      margin: 16px 0;
      .modalIcon{
        width: 60px;
        height: 60px;
        margin-right: 20px;
      }
      h1{
        margin: 0;
        @include fontMulish(36px,rgba(0, 0, 0, 0.85),900,120%);
      }
    }
    .description{
      @include font(16px,rgba(0, 0, 0, 0.85),700,24px);
      .greenText{
        @include fontMulishBold(16px,rgba(0, 0, 0, 0.85),700, 24px)
      }
      .blueText{
        color: #1890ff;
        outline: none;
      }
      .blueLinkText{
        color: #1890ff;
        outline: none;
        cursor: pointer
      }
      .modalImage{
        width: 400px;
        border-radius: 16px;
        margin-left: 16px;
        margin-bottom: 16px;
        float:right;
      }
      .alert{
        @include fontMulishBold(18px,rgba(0, 0, 0, 0.85),900,22px);
      }
      .list{
        .mainList{
          li{
            @include fontMulishBold(16px,rgba(0, 0, 0, 0.85),900,26px);
            .textDescription{
              @include font(16px,rgba(0, 0, 0, 0.85),700,24px); 
            }
          }
          .subList{
            list-style: decimal;
            li{
              font-family: LatoRegular, sans-serif !important;
              font-weight: 700;
              line-height: 24px;
              //@include font(16px,rgba(0, 0, 0, 0.85),700,24px);
            }
          }
        }
      }
      .subTitle{
        @include fontMulishBold(18px,rgba(0, 0, 0, 0.85),900,28px);
        margin: 12px 0;
      }
      .footer{
        @include fontMulishBold(16px,rgba(0, 0, 0, 0.85),900,26px);
      }
    }
  }
  .modalButtons{
    display: flex;
    justify-content: flex-end;
    padding: 17px 32px;
    .button{
      width: max-content;
      padding: 8px 32px;
      margin-left: 8px;
    }
  }
}

.container {
  background-color: white;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 108px;


  h2 {
    margin: 0 0 56px 0;
    @include fontMulish(40px, #262626, 900, 48px);
    text-align: center;
  }

  .infoWrapper {
    max-width: 1156px;
    margin: 0 auto;
    display: grid;
    grid-template:
      repeat(2, 106px) 24px 82px 24px 106px 106px / repeat(4, 1fr 24px)
      1fr;

    .serviceCard {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      padding: 8px;
      background: #ffffff;
      box-shadow: 0px 11px 24px rgba(55, 78, 94, 0.11);
      border-radius: 6px;
      transition: 0.6s;

      div {
        @include font(16px, $header-color, $fw-bold, 24px);
        text-align: center;
        height: 46px;
      }
      
      h3{
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 100%;
        color: rgba(0, 0, 0, 0.85);
      }
      .mainHeader{
        font-size: 24px;
      }
      .mainP{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .serviceCard:hover{
      transform: scale(1.1);

    }

    .getStarted {
      grid-row: 1/1;
      grid-column: 5/6;
      display: flex;
      flex-direction: column;
      align-items: center;
      div {
        margin-bottom: 13px;
        text-align: center;
        @include font(18px,$color: $devider-grey);
        font-weight: 700;
      }
      button{
        animation: pulse 2s infinite;
        width: 100%;
        height: 40px;
        font-size: 16px;
        font-weight: 700;
      }
    }

    .serviceCard:nth-child(1) {
      grid-row: 2/5;
      grid-column: 1/2;
    }
    .serviceCard:nth-child(2) {
      grid-row: 1/3;
      grid-column: 3/4;
    }
    .serviceCard:nth-child(3) {
      grid-row: 2/5;
      grid-column: 5/6;
    }
    .serviceCard:nth-child(4) {
      grid-row: 1/3;
      grid-column: 7/8;
    }
    .serviceCard:nth-child(5) {
      grid-row: 2/5;
      grid-column: 9/10;
    }
    .serviceCard:nth-child(6) {
      grid-row: 6/8;
      grid-column: 1/2;
    }

    .serviceCard:nth-child(7) {
      grid-row: 4/7;
      grid-column: 3/4;
    }

    .serviceCard:nth-child(8) {
      grid-row: 6/8;
      grid-column: 5/6;
    }

    .serviceCard:nth-child(9) {
      grid-row: 4/7;
      grid-column: 7/8;
    }

    .serviceCard:nth-child(10) {
      grid-row: 6/8;
      grid-column: 9/10;
    }
  }
}

.show890 {
  display: none;
}

@media screen and (max-width: 1190px) {
  .container {
    .infoWrapper {
      max-width: 893px;
      grid-template:
        repeat(2, 80px) 16px 64px 16px 80px 80px / repeat(4, 165px 16px)
        165px;

      .getStarted {
        margin-bottom: 12px;
        max-width: 165px;
      }

      h3{
        font-size: 18px !important;
      }

      .mainHeader{
        font-size: 20px!important;
      }
      .mainP{
        display: none;
       }
    }
  }
}

@media screen and (max-width: 900px) {
  .modalBody{
    .main{
      .description{
        .modalImage{
          width: 300px;
          margin-bottom: 8px;
        }
      }
    }
  }
  .show890 {
    display: block;
    text-align: center;

    div {
      margin-bottom: 13px;
      text-align: center;
      @include font(16px,rgba(0, 0, 0, 0.45),500,24px);
      font-weight: 700;
    }

    button{
      animation: pulse 2s infinite;
      width: 100%;
      height: 40px;
      font-size: 16px;
      font-weight: 700;
    }
  }
  .container {
    .infoWrapper {
      grid-template-columns: 1fr 312px 24px 312px 1fr;
      grid-template-rows: repeat(5, 212px 24px);

      h3{
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 24px !important;
      }

      .hideMax890 {
        display: none;
      }

      .getStarted{
        button{
          font-weight: 700 !important;
        }
      }

      .serviceCard img{
        width: 106px;
        height: 106px;
      }
      .serviceCard:nth-child(1) {
        grid-row: 1/2;
        grid-column: 2/3;
      }
      .serviceCard:nth-child(2) {
        grid-row: 3/4;
        grid-column: 2/3;
      }
      .serviceCard:nth-child(3) {
        grid-row: 5/6;
        grid-column: 2/3;
      }
      .serviceCard:nth-child(4) {
        grid-row: 7/8;
        grid-column: 2/3;
      }
      .serviceCard:nth-child(5) {
        grid-row: 9/10;
        grid-column: 2/3;
      }
      .serviceCard:nth-child(6) {
        grid-row: 1/2;
        grid-column: 4/5;
      }

      .serviceCard:nth-child(7) {
        grid-row: 3/4;
        grid-column: 4/5;
      }

      .serviceCard:nth-child(8) {
        grid-row: 5/6;
        grid-column: 4/5;
      }

      .serviceCard:nth-child(9) {
        grid-row: 7/8;
        grid-column: 4/5;
      }

      .serviceCard:nth-child(10) {
        grid-row: 9/10;
        grid-column: 4/5;
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .modalBody{
    .main{
      .modalTitle{
        h1{
          font-size: 24px;
        }
      }
    }
    .modalButtons{
      padding: 17px 0;
    }
  }
  .container {
    .getStarted {
      div {
       color: rgba(0, 0, 0, 0.45);
      }
    }
    .infoWrapper {
      grid-template-columns: 1fr 212px 16px 212px 1fr;
      grid-template-rows: repeat(5, 160px 16px);

      .serviceCard img{
        width: 53px;
        height: 53px;
      }
    }
  }
  p{
    margin-bottom: 1em !important;
  }
}

@media screen and (max-width: 480px) {
  .modalBody{
    .main{
      .description{
        .modalImage{
          display: none;
          width: 200px;
          margin-bottom: 8px;
        }
      }
    }
  }
  .container {
    padding: 40px 0;
    h2{
      //font-weight: 600 !important;
      font-size: 26px !important;
      line-height: 120% !important;
      margin-bottom: 24px; 
    }
    .infoWrapper {
      grid-template-columns: 1fr 163.5px 9px 163.5px 1fr;
      grid-template-rows: 60px 16px 60px 16px 60px 16px 60px 16px 74px;
      margin-bottom: 24px;

      .serviceCard {
        display: flex;
        flex-direction: row;
        justify-content: start;
        padding: 0;
        gap: 0;
        h3{
          width: 90px;
          margin: 0;
          font-weight: 500 !important;
          font-size: 14px !important;
          line-height: 22px !important; 
        }
        div {
          text-align: left;
          display: flex;
          justify-content: start;
          align-items: center;
          width: 100px;
          @include font(14px, $header-color, 500, 22px);
          
        }
      }
    }

    
  }
}

// @media screen and (max-width: 369px) {
//   .container {
//     .infoWrapper {
//       grid-template-columns: 24px 1fr 24px;
//       grid-template-rows: repeat(10, 160px 24px);

//       .getStarted {
//         grid-row: 21/23;
//         grid-column: 2/3;
//       }

//       .serviceCard:nth-child(1) {
//         grid-row: 1/2;
//         grid-column: 2/3;
//       }
//       .serviceCard:nth-child(2) {
//         grid-row: 3/4;
//         grid-column: 2/3;
//       }
//       .serviceCard:nth-child(3) {
//         grid-row: 5/6;
//         grid-column: 2/3;
//       }
//       .serviceCard:nth-child(4) {
//         grid-row: 7/8;
//         grid-column: 2/3;
//       }
//       .serviceCard:nth-child(5) {
//         grid-row: 9/10;
//         grid-column: 2/3;
//       }
//       .serviceCard:nth-child(6) {
//         grid-row: 11/12;
//         grid-column: 2/3;
//       }

//       .serviceCard:nth-child(7) {
//         grid-row: 13/14;
//         grid-column: 2/3;
//       }

//       .serviceCard:nth-child(8) {
//         grid-row: 15/16;
//         grid-column: 2/3;
//       }

//       .serviceCard:nth-child(9) {
//         grid-row: 17/18;
//         grid-column: 2/3;
//       }

//       .serviceCard:nth-child(10) {
//         grid-row: 19/20;
//         grid-column: 2/3;
//       }
//     }
//   }
// }
