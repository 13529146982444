@import '../../../Theme/theme';

.mainBlock {
  //height: 80px;
  position: absolute;
}

.container {
  width: 100%;
  padding: 8px 40px;
  position: fixed;
  background-color: white;
  z-index: 16;
  //box-shadow: 0px 2px 8px #F0F1F2;

  .forWidth {
    //max-width: 1360px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    .menu {
      display: flex;
      list-style-type: none;
      justify-content: space-between;
      align-items: center;
      @include font($color: $header-color);
      font-weight: 600;
      margin: 0 !important;
      width: 100%;
      max-width: 564px;
      padding: 0;
      li {
        cursor: pointer;
        white-space: nowrap;
        a {
          color: $header-color;
        }
        margin: 13px 20px;
        gap: 10px;
      }
    }
    .leftWrapper {
      display: flex;
      width: 100%;
    }

    .whatsapp{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 76px;
      height: 36px;
      padding: 6px 20px;
    }

    .rightWrapper {
      display: flex;
      align-items: center;
      max-width: 454px;

      .menu {
        max-width: 260px;
        li {
          color: $primary-dark;
          a{
            color: #026BB5;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
      .buttonSignUp{
        max-width: 116px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        span{
          width: 52px;
          height: 24px;
        }
      }
    }
  }
}

.burgerButton {
  display: none;
  font-size: 24px;
  color: $primary-dark;
  padding-top: 10px; 
}

@media screen and (max-width: 1300px) {
  .mainBlock {
    height: 56px;
  }
  .burgerButton {
    display: block;
  }
  .container {
    padding: 8px 21px;
    .forWidth {
      .leftWrapper {
        display: none;
      }
      .rightWrapper {
        display: none;
      }
      .whatsapp{
        width: 36px;
        padding: 0;
        margin: 3px 0 0 0;
      }
    }
  }
}

@media screen and (max-width: 428px) {
  .container {
    .forWidth {
      //justify-content: space-around;
      .carLogicIcon{
        display: flex;
        align-items: center;
        img{
          width: 100px;
          height: 36px;
        }
      }
    }
  }
}