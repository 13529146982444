@import '../../Theme/theme';

.emptyScreenContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 188px);
 
  
  h2 {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    color: #5b8db4;
    font-size: 30px;
    line-height: 40px;
  }
}
