  @import '../../Theme/theme';

.mainLayout {
  padding: 16px;
}

.menuContainer {
  border: none;
  @include font($color: $side-menu-font-color);
}
.logo {
  height: 48px;
  margin-top: 32px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}

.payments{
  margin-left: 16px;
  margin-right: 16px;
  border-top: 1px solid #F0F0F0;
  margin-bottom: 238px;
  @include fontMulishBold(18px, rgba(0, 0, 0, 0.45), 800, 120%);
  p{
    margin-bottom: 4px;
  }
  .paymentIcons{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

}

.sideBarContainer {
  border-radius: 6px;
  background-image: url(../../Assets/sideBarImage.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  flex-direction: column;
  position: sticky;
  top: 16px;
  height: 96vh;
  z-index: 2;
  div[class='ant-layout-sider-children']{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.none{
  display: none;
}
.siteLayoutSubHeaderBackground {
  background: $white;
  margin-left: 24px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  min-height: 70px;
  .whatsapp{
    display: flex;
    align-items: center;
    img{
      padding-right: 8px;
    }
    a{
      width: 36px;
      height: 36px;
    }
  }

  .breadCrumbsLink {
    @include font(18px, $header-color, $fw-regular, 28px);
    text-transform: capitalize;
  }
  
  .breadCrumbsLink:hover {
    @include font(18px, $primary-color, $fw-regular, 28px);
  }
}

.siteLayoutBackground {
  background: $white;
  border-radius: 6px;
  min-height: calc(100vh - 126px);
  padding: 24px;
  overflow: hidden;
}

.removeHorizontalPadding {
  padding: 24px 0 24px 0 !important;
}




.content {
  margin: 20px 0px 0px 24px;
}

@media (max-width: 991px) {
  .siteLayoutSubHeaderBackground {
    margin-left: 0px;
    min-height: 64px;
    padding: 0 16px;
  }
  .siteLayoutBackground {
  padding: 24px 16px;
  }
  .content {
    margin: 24px 0px 0px 0px;
  }

  .sideBarContainer {
    position: fixed;
    //background-color: rgba(0, 0, 0, 0.25);
    height: 97vh;
    top: 16px;
  } 
}

@media (max-width: 540px) {
  .mainLayout {
    padding: 0;
    
  }
  .content{
    margin-top: 64px;
  }
  .siteLayoutSubHeaderBackground {
    width: 100%;
    position: fixed;
    z-index: 1;
  }
  .whatsapp{
    // span{
    //   display: none;
    // }
  }
}
