@import '../../../../../../Theme/theme';

.container {
  .cardContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
  }
  .header {
    @include fontMulishBold(24px, $header-color, 900, 32px);
    padding-bottom: 24px;
  }

  .link {
    display: block;
    margin-bottom: 24px;
    @include font($color: $primary-color, $weight: 700);
    text-decoration: underline;
  }
}

@media screen and (max-width: 540px) {
  .container {
    .cardContainer {
      gap: 16px;
    }
  }
  .header{
    margin: 0;
  }
}

@media screen and (max-width: 370px) {
  .container {
    .cardContainer {
      gap: 0;
    }
  }
}