@import '../../../Theme/theme';

.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .devider {
    margin-right: 16px;
    margin-left: 16px;
    width: calc(100% - 32px);
    min-width: 0;
    @include font()
  }
  
}

