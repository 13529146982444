@import '../../../../../Theme/theme';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 304px;
  //height: max-content;
  max-height: 160;
  flex-basis: 50%;
  .title {
    text-align: center;
    margin: 0;
    margin-top: 8px;
    //color: rgba(0, 0, 0, 0.85) !important;
    @include font(24px, white, 500, 32px);
    p{margin: 0;}
  }
}

@media screen and (max-width: 700px) {
  .container {
    flex-direction: row;
    padding: 16px 4px 16px 80px;
    max-width: 100%;
    justify-content: start;
    .title{
      padding-left: 30px;
      max-width: 375px;
      font-weight: 700 !important;
      font-size: 22px !important;
    }
  }
}
@media screen and (max-width: 555px) {
  .container {
    flex-direction: row;
    padding: 0px 4px 0px 15px;
    max-width: 100%;
    min-height: 90px;
    align-items: center;
    justify-content: start;
    .title{
      text-align: start;
      padding: 0;
      max-height: 92;
      max-width: 375px;
      margin-top: 0px;
      //margin-bottom: 10px;
    }
  }
}
@media screen and (max-width: 480px) {
  .container {
    // .title{
    //   display: flex;
    //   align-items: center;
    //   text-align: start;
    //   margin-bottom: 20px;
    // }
    .title{
      text-align: start;
      padding: 0;
      max-height: 92;
      max-width: 375px;
      margin-top: 0px;
      padding-left: 16px;
      //margin-bottom: 10px;
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 28px !important;
    }
  }
}
