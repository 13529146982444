@import '../../../../Theme/theme';

.container {
  display: flex;
  flex-direction: column;
  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.45);
  }
  .par {
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
  li {
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-left: 0px;
  }
  ol {
    padding-left: 20px;
  }
}
