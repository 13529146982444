@import '../../Theme/theme';


.card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;

    img {
       margin-bottom: 4px;
    }

    span:nth-child(2) {
        margin-right: 8px;
        text-transform: capitalize;
    }
    span:last-child {
        margin-left: 12px;
    }
}