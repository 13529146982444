@import '../../Theme/theme';

.verticalDots {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.link {
  cursor: pointer;
}

.linkColor {
  @include font(14px, $primary-color, $fw-regular, 22px);
  text-decoration: underline;
}
.downloadIcon {
  color: $devider-grey;
  cursor: pointer;
}

.disabledIcon:not(#notExist) {
  cursor: auto;
  opacity: .2;
}
