@import '../../Theme/theme';

.buttonDrawer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 50px;
  display: none;
  border: none;
  z-index: 999;
  background: #D55672;
  height: 50px;
  border-radius: 50%;

  .icon {
    font-size: 20px;
    color: $white;
  }
}

.mobileContainer{
  background-repeat: no-repeat;
  background-color: #D55672;
  // background-image: url(../../Assets/orderIcon/mobile-createorder-bg.svg);
  // background-size: contain;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  display: none;
  max-width: 570px;
  width: 100%;
  height: 62px;
  
  .leftWrapper{
    display: flex;
    flex-direction: row;
    .carWrapper{
      width: 92px;
      height: 62px;
      .whiteBackground{
        position: absolute;
        //background-image: url(../../Assets/orderIcon/car-details-white-bg.svg);
        width: 68px;
        z-index: 0;
        height: 62px;
        //background-size: cover;
      }
      .carImageSrc{
        position: absolute;
        z-index: 1.5;
        margin: 6px;
        margin-left: 16px;
        width: 76px;
        height: 50px;
      }
    }
    .headInfo{
      margin-bottom: 6px;
      margin-top: 6px;
      margin-left: 20px;
      //margin: 4px 20px 6px 14px;
      h4{
        @include font(14px, $white, $fw-bold, 16px);
        margin: 0;
      }
      .textArea{
        height: 18px;
        margin: 0;
        padding: 0;
        b {
          @include font(12px, $white, 400, 16px);
          //margin-left: 10px;
        }
        .engineMilliage {
          @include font(12px, $neutral, $fw-regular, 16px);
          width: 42px;
          display: inline-block;
        }
      }
    }
  }
  .summary{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 126px;
    height: 48px;
    margin: 7px 0;
    background-color: white;
    border-radius: 40px 0 0 40px;
    transition: 1s;
    &:hover {
      transform: scale(1.05);
    }
    &:active {
      transform: scale(0.95);
    }
    box-shadow: 0px 10px 15px rgba(124, 16, 40, 0.47);
    .titles{
      margin-right: 15px;
      margin-left: 10px;
      margin-bottom: 5px;
      h4{
        margin: 0;
        @include font(14px, #D55672, 700, 22px);
      }
      h3{
        margin: 0;
        @include fontMulishBold(12px, #141414, 800, 110%);
      }
    }
    .doubleArrow{
      height: 16px;
      width: 16px;
      color: #D55672;
      @include font(16px, #D55672, 500, 16px);    
    }
  }
}

.animationCircle {
  animation: toLeft 800ms;
}

.animationCircle::before {
  content: "";
  position: absolute;
  top: -45px;
  right: -100px;
  background-color: #EFF2F5;
  border-radius: 90% 40% 40% 90%;
  width: 70px;
  height: 140px;
}


.animationCircle::after {
  content: '';
  width: 30px; height: 30px;
  border-radius: 100%;
  border: 12px solid #D55672;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 800ms;
}

/* .animationRun::after {
  animation-play-state:running;
} */

@keyframes ring {
  0% {
    width: 50px;
    height: 50px;
    opacity: 1;
  }
  100% {
    width: 100px;
    height: 100px;
    opacity: 0;
  }
}

@keyframes toLeft {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-40px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes convex {
  0% {
    right: -70px;
  }
  50% {
    right: -60px;
  }
  100% {
    right: -70px;
  }
}

.buttonDrawer:hover {
  color: $primary-color;
  transform: scale(1.1);
}

@media (max-width: 1310px) {
  .buttonDrawer {
    display: flex;
    position: fixed;
    right: 16px;
    top: 70px;
  }
  .mobileContainer{
    display: flex;
  }
}
@media (max-width: 570px) {
  .headInfo{
    margin-left: 10px !important;
  }
}
@media (max-width: 460px) {
  .leftWrapper{
    .headInfo{
      h4{
        width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
@media (max-width: 420px) {
  .leftWrapper{
    .headInfo{
      h4{
        width: 145px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}