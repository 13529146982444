.card {
    box-sizing: border-box;
    margin-top: 5px;
    padding: 10px 12px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: white;
}

.customerCard {
    width: 380px;
}