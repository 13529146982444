@import '../../Theme/theme';

.container {
  //background-color: #f8fbfc;
  background-color: $white;
  //height: 100vh;
  //padding: 0 16px 0 16px;
  .header{
    position: fixed;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    z-index: 16;
    .logoIcon {
      margin-left: 30px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    .whatsapp{
      display: flex;
      align-items: center;
      width: 36px;
      padding-right: 60px;
      //height: 36px;
    }
  }
  div[class='siteLayoutBackground']{
    padding: 24px 0 !important;
  }
  .centerBlock {
    max-width: 1168px;
    margin: 0 auto;
    //margin-top: 81px;
    padding: 105px 0px 24px 0px;
    //padding: 24px;
    background-color: white;
    border-radius: 6px;
  }
}

@media (max-width: 1150px) {
  .container {
    padding: 0;
    .logoIcon {
      margin-left: 5px;
      margin-top: 3px;
      margin-bottom: 8px;
    }
  }
}
@media screen and (max-width: 428px) {
  .container {
    .centerBlock{
      padding-top: 76px;
    }
    .logoIcon {
      margin-left: 15px !important;
      margin-top: 8px !important;
      margin-bottom: 8px !important;
      width: 100px;
      height: 36px;
    } 
  }
}
