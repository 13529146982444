@import '../../../Theme/theme';

.contain {
  max-width: 476px;
  height: 680px;
  border-radius: 6px;
  padding: 56px;
  background-color: $white;
  margin: 70px auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .textContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .textBlock {
      @include font(18px, $header-color, $fw-regular, 28px);
      margin-bottom: 32px;
      text-align: center;
    }
  }

  .signature {
    text-transform: uppercase;
    @include font(20px, $devider-grey, $fw-bold, 32px);
    margin-bottom: 32px;
  }
}

.width250 {
  max-width: 250px;
}
