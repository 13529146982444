@import './../../Theme/theme';

.tableHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.button {
  margin-left: 12px;
  height: 40px;
  border: none;
}

.saveButton {
  background: linear-gradient(to right, $primary-light, $blue-light);
  width: 180px;
}
