@import '../../../../../../Theme/theme';

.loginForm {
  margin-top: 24px;
}

.input {
  color: $primary-color;
  height: 44px !important;
  div{
    height: 44px !important;
    margin-bottom: 16px;
  }
  span[class='ant-input-affix-wrapper']{
    height: 44px !important;

  }
}

.phoneInput {
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }
  }

.acceptContainer {
  position: relative;

  .errorMessage {
    position: absolute;
    color: red;
    top: -18px;
    left: 15%;
  }
}

.checkBoxText {
  @include font($size: 12px, $color: $font-color);
  max-width: 300px;
  margin-bottom: 24px;
  align-items: center;

  span {
    @include font($size: 12px, $color: $primary-color);
  }
}

.checkBoxSaveText {
  @include font($size: 12px, $color: $font-color);
}

.checkboxSave {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}

.buttonSocial {
  width: 100%;
  display: block;
  margin: 20px 0;

  .btnText {
    margin-left: 12px;
  }
}

.paymentcontainer {
  .subtitle {
    @include font($size: 16px, $color: $primary-color);
    max-width: 360px;
  }

  .withCardBook {
    margin-top: 4px;
    @include font($color: $devider-grey);
  }
  margin-bottom: 20px;
  // min-width: 25em;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
@media screen and (max-width: 570px) {
  .buttons {
    justify-content: center;
  }
}
