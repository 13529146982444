@import '../../../Theme/theme';

.container {
  padding: 24px 16px;

  .form {
    display: flex;
    justify-content: space-between;
    max-width: 950px;
    flex-wrap: wrap;

    .leftForm {
      flex-basis: 360px;

      .managePassword {
          @include font ($color: $primary-color);
          cursor: pointer;
          text-decoration: underline;
      }
    }

    .rightForm {
      flex-basis: 480px;
      .header {
        @include font(16px, $devider-grey, $fw-regular, 24px);
        margin-left: 120px;
        margin-bottom: 16px;
      }
      .buttons{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        max-width: none;
      }
    }
  }
}
.input {
  color: $primary-color;
}

@media (max-width: 400px) {
  .container {
    .form {
      .rightForm {
        .buttons{
          max-width: 325px;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .container {
    .form {
      .rightForm {
        .header {
          margin-left: 0;
        }
      }
    }
  }
}

@media (max-width: 770px) {
  .container {
    .form {
      //flex-wrap: wrap;
      //justify-content: start;
    }
  }
}

@media (max-width: 903px) {
  .container {
    .form {
      .rightForm {
        margin-top: 42px;
      }
    }
  }
}
