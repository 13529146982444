@import '../../../../Theme/theme';

.container {
  background:linear-gradient(0deg, rgba(2, 107, 181, 0.3), rgba(2, 107, 181, 0.3)), linear-gradient(264.41deg, #A3EFD3 4.48%, #67BFDC 59.81%, #2084CA 97.42%);
  padding: 64px 16px;
  z-index: 1;

  .contentWrapper {
    max-width: 1216px;
    margin: 0 auto;
    .cardContainer {
      display: flex;
      //justify-content: space-between;
      //gap: 8px;
      .couple {
        display: flex;
        //justify-content: space-around;
        margin-bottom: 24px;
        //gap: 8px;
        flex-basis: 100%;
      }

    }

    h2 {
      @include fontMulish(40px, white, 900, 48px);
      text-align: center;
      margin: 0px 0 56px 0;
    }
  }
}

@media screen and (max-width: 700px) {
  .container {
    margin-bottom: 32px;
    padding: 40px 16px 32px;
    .contentWrapper {
      h2{
        margin-bottom: 20px;
      }
      .cardContainer {
        .couple{
          flex-direction: column;
          gap:8px;
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container {  
    h2 {
    font-size: 26px !important;
    line-height: 120% !important;
    }
  }
}

@media screen and (max-width: 428px) {
  .container {  
    padding: 0;
    margin-top: 32px;
    padding-top: 32px;
    padding-bottom: 17px;
    margin-bottom: 32px;
    align-items: center;
  }
}

// @media screen and (max-width: 340px) {
//   .container {
//     .contentWrapper {
//       .cardContainer {
//         .couple {
//           flex-wrap: wrap;
//         }
//       }
//     }
//   }
// }
