@import '../../../../Theme/theme';

.contentContainer {
  display: flex;
  justify-content: center;
  gap: 60px;
}
.tabbyPromo{
  display: none;
}
.leftWrapper {
  max-width: 570px;

  .dropDownContainer {
    margin-bottom: 24px;
    .dropDownHeader {
      @include font($color: $primary-color, $weight: $fw-bold);
      margin-bottom: 12px;
      cursor: pointer;
    }

    .close {
      position: relative;
      &::after {
        content: '';
        position: relative;
        top: -10px;
        left: 4px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #5b8db4;
      }
    }

    .open {
      position: relative;
      margin-bottom: 40px;
      &::after {
        content: '';
        position: relative;
        top: 10px;
        left: 4px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #5b8db4;
      }
    }

    .dropDown {
      .content {
        ul {
          @include font();
          padding-left: 20px;
        }

        .header {
          @include font(18px, $devider-grey, $fw-regular, 28px);
        }
      }
    }

  }
  
  .freepoints{
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border: none;
    box-shadow: 0px 11px 24px rgba(55, 78, 94, 0.11);
    border-radius: 40px;
    @include font(16px, #003356, 700, 24px);
    display: flex;
    margin-bottom: 24px;
    justify-content: center;
    align-items: center;
    img{
      padding-left: 8px;
    }
    position: relative;
    transition: 0.6s;
    &:hover {
      transform: scale(1.05);
    }
    &:active {
      transform: scale(0.95);
    }
  }
  .lubricants{
    p{
      margin: 0;
      @include font(16px, rgba(0, 0, 0, 0.85), 400, 24px);
    }
  }
  .textArea{
    margin-top: 36px;
     padding: 10px 0px;
  }
}
.hideDropDown {
  display: none;
}
.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}

.alertBody {
  padding-top: 32px;
  @include font(16px, $black-color, $fw-bold, 24px);
  display: flex;
  gap: 16px;
}

.partsList {
  max-height: 500px;
  overflow-y: scroll;
  .header {
    @include font(16px, $black-color, 500, 24px);
    margin: 16px 16px 16px 16px;
  }

  .content {
    display: flex;
    justify-content: space-between;
  }
  .leftBlock {
    flex: 50%;
  }
  .rightBlock {
    flex: 50%;
  }

  .row {
    @include font($color: $header-color);
    display: flex;
    padding: 9px;
    align-items: center;
    margin: 0 16px;
    gap: 9px;
    border-bottom: 1px solid #f0f0f0;
  }
}

@media (max-width: 1310px) {
  .contentContainer {
    margin-left: 0px;

    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .leftWrapper{
    margin: 0 16px;
  }
  .hide {
    display: none;
  }
  .tabbyPromo{
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 10px;
    margin-bottom: 20px;
    p{
      //margin: auto;
      margin: auto  0;
      @include font(16px, rgba(0, 0, 0, 0.85), 700, 24px);
    }
    img{
      width: 78px;
      height: 32px  ;
    }
  }
}

@media (max-width: 585px) {
  .contentContainer {
    gap: 16px;
  }
  .leftWrapper{
    .lubricants{
      img{
        width: 110px;
      }
    }
  }
}

@media (max-width: 585px) {
  .partsList{
    .content {
      flex-direction: column;
    }
  }
  .leftWrapper{
    .freepoints{
      font-size: 14px;
      line-height: 22px;
      box-shadow: 0px 0px 4px rgba(30, 72, 102, 0.03), 0px 3px 8px rgba(30, 72, 102, 0.1);
    }

    .textArea{
      margin-top: 0;
    }

    .lubricants{
      //display: flex;
      div{
        display: flex;
        justify-content: center;
        img{
          width: 78px;
        }
      }
      p{
        text-align: center;
      }
    }
  }
  .buttons {
    margin-top: 36px;
    justify-content: center;
  }
}
@media (max-width: 370px) {
  .leftWrapper {
    overflow: hidden;
  }
}
