@import '../../../../Theme/theme';

.mainWrapper {
  background-color: white;
  width: 100%;
  padding-bottom: 70px;
  padding-top: 140px;
  .container {
    background-position: 90% 100%;
    background-color: white;
    max-width: 1440px;
    margin: 0 auto;
    height: 460px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    .bannerContent {
      margin-left: 142px;
      max-width: 380px;
      h1 {
        margin-bottom: 0;
        span {
            max-width: 300px;
        }
      }
    }
    .backgroundImage{
      background-image: url(../../../../Assets/Landing/external-links-image.svg);
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      max-width: 643px;
      height: 460px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .mainWrapper {
    padding: 0;
    .container {
      height: 580px;
      flex-direction: column;
      .bannerContent {
        margin: 32px auto 0 auto;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .mainWrapper {
    .container {
      height: 423px;
      background-position: 90% 100%;
      .bannerContent {
        margin: 32px auto 0 auto;
        text-align: center;
      }
      .backgroundImage{
        width: 450px;
        height: 303px;
      }
    }
  }
}

@media screen and (max-width: 475px) {
  .mainWrapper {
    .container {
      height: 310px;
      .bannerContent {
        margin: 32px auto 0 auto;
        text-align: center;
        h1{
          font-weight: 500;
          font-size: 30px;
          line-height: 40px;
        }
      }
      .backgroundImage{
        width: 343px;
        height: 226px;
        max-width: 343px !important;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .mainWrapper {
    .container {
      height: 350px;
    }
  }
}