@import '../../../../../../Theme/theme';

.arrow:not(#not-exist-id) {
  //background: #E6F7FF;;
  width: 68px;
  height: 28px;
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-radius: 2px;
  z-index: 1;
  top: 25%;
}

.arrow:not(#not-exist-id)::before {
  content: '';
  position: absolute;
  top: 33%;
  right: 42.5%;
  z-index: 2;
  color: #026BB5;
  width: 10px;
  height: 10px;
  border-right: 2px solid currentcolor;
  border-bottom: 2px solid currentcolor;
  transform: rotate(135deg);
}
.arrowRight:not(#not-exist-id)::before {
  content: '';
  transform: rotate(-45deg);
}

.container {
  //margin-top: 60px;
  max-width: 546px;
  overflow: hidden;
  .subtitle {
    @include font($color: $devider-grey);
  }
}
.worksTime{
  @include fontMulishBold(18px, rgba(0, 0, 0, 0.85), 900, 28px);
  margin-bottom: 4px;
}
.alert{
  @include font(14px, #FF4D4F, 500, 22px);
  margin-top: -24px;
  margin-bottom: 40px
}
.adress{
  // display: flex;
  // flex-direction: column;
  @include font(14px, rgba(0, 0, 0, 0.85), 700, 22px);
  margin: 16px 0;
  margin-top: 40px;
}
.input {
  max-width: 311px;
  margin-bottom: 4px;
  div[class='ant-select']{
    height: 44px !important;
  }
}

.timeButtons{
  max-width: 550px;
  //padding-left: 4px;
  .group{
    display: flex;
    max-width: 546px;
    width: 100%;
    //padding-left: 4px;
    justify-content: space-around;
    flex-wrap: wrap;
    .time{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 105px;
      height: 46px;
      @include fontMulishRegular(18px, rgba(0, 0, 0, 0.85), 400, 120%);
      border: 1px solid #F0F0F0;
      border-radius: 2px;
      margin-bottom: 4px;
      margin-right: 4px;
      padding: 0 !important;
      transition: 1s;
      &:hover {
        transform: scale(1.05);
      }
      &:active {
        transform: scale(0.95);
      }
      label[class='ant-radio-button-wrapper-checked']{
        background-color: #026BB5;
      }
    }
  }

}

.date{
  max-width: 536px;
  overflow: hidden;
  .dateButton{
    border: none;
    padding: 0;
    width: 68px;
    height: 68px;
    background-color: white;
    .card{
      width: 68px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 68px;
      background-color: white;
      border: 1px solid #F0F0F0;
      border-radius: 2px;
      .name{
          @include font(14px,rgba(0, 0, 0, 0.85), 400, 22px);
          margin-bottom: 4px;
      }
      .number{
          @include fontMulishRegular(30px,rgba(0, 0, 0, 0.85), 400, 120%);
  
      }
    }
  }

  .disabled{
      opacity: 0.4;
  }

  .enabled{
      transition: 1s;
      &:hover {
        transform: scale(1.05);
      }
      &:active {
        transform: scale(0.95);
      }
  }
  .month{
    height: 20px; 
    @include fontMulishBold(18px,rgba(0, 0, 0, 0.85), 800, 120%);
    text-align: center;
    position: relative;
    margin: 0;
    top: 45px;
  }
  .active{
      background-color: #026BB5 !important;
      border: none !important;
      .name{
        color: white !important;
        margin-bottom: 4px;
      }
      .number{
        color: white !important;
      }
  }
  .carousel{
    padding-top: 60px;
  }
}

.calendar{
  width: 300px;
  label{
    display: none;
  }
  // div[class='ant-radio-group']{
  //   display: none;
  // }
}
.forms{
  //margin-top: 40px;
}
.notMargin {
  margin-top: 16px;
}

.header {
  @include fontMulishBold(24px, $header-color, 900, 32px);
}

.primaryColor {
  @include font(24px, $primary-color, $fw-regular, 32px);
}

.buttons {
  width: 100%;
  margin-top: 36px;
  display: flex;
  justify-content: flex-end;
  //margin-right: -25px;
}

@media screen and(max-width: 540px) {
  .container {
    margin: 0 16px;
  }
  .arrow:not(#not-exist-id) {
    top: 10%;
  }
  .date{
    margin: 0;
    max-width: 400px;
    width: 100%;
    overflow: hidden;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    .dateButton{
      border: none;
      padding: 0;
      width: 68px;
      height: 68px;
      background-color: white;
      .card{
        width: 44px;
        height: 48px;
        .name{
          font-size: 12px;
          line-height: 120%;
          margin-bottom:  2px;     
        }
        .number{
          font-size: 22px;
          line-height: 120%;
        }
      }
    }
    .month{
      top: 22px;
    }
  }
  .timeButtons{
    width: 100%;
    //padding-left: 4px;
    .group{
      display: flex;
      max-width: 360px;
      margin: 0 auto;
      width: 100%;
      //padding-left: 4px;
      justify-content: space-around;
      flex-wrap: wrap;
      .time{
        width: 64px;
        height: 38px;
        margin-right: 8px;
        font-size: 12px;
        line-height: 120%;  
      }
    }
  
  }
  .buttons{
    justify-content: center;
  }
  .header{
    margin-left: 0 !important;
  }
}
@media screen and(max-width: 420px) {
  .container {
    margin: 0 16px;
  }
  .date{
    margin: 0;
    max-width: 343px;
    width: 100%;
    overflow: hidden;
    display: flex;
    margin: 0;
    justify-content: center;
  }
  .timeButtons{
    width: 100%;
    //padding-left: 4px;
    .group{
      display: flex;
      max-width: 340px;
      margin: 0;
      width: 100%;
      //padding-left: 4px;
      justify-content: space-around;
      flex-wrap: wrap;
      .time{
        width: 64px;
        height: 38px;
        margin-right: 4px;
        font-size: 12px;
        line-height: 120%;  
      }
    }
  
  }
  // .forms{
  //   max-width: 320px;
  // }
  .alert{
    max-width: 336px;
  }
  .inputAddress {
    max-width: 311px;
  }
  .input {
    max-width: 311px;
  }
}
