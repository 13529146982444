// h1{
//     margin-bottom: 8px;
//     font-style: normal;
//     color: rgba(0, 0, 0, 0.85);
//     width: max-content;
//     height: 46px;
//     font-weight: 600;
//     font-size: 40px;
//     line-height: 46px;
//   }
//   h1::before,.switchTitle::after{
//     content: "";
//     position: absolute;
//     top: 0;
//     right: 0;
//     left: 0;
//     bottom: 0;
//   }
//   h1::before{
//     height: 46px;
//     background: white;
//     animation: typewriter 24000ms steps(24) forwards ;
//   }
//   h1::after{
//     height: 46px;
//     width: 0.125em;
//     background-color: black;
//     animation: typewriter 000ms steps(24,end) forwards, hideTitle 3000ms linear;
//   }
//   @keyframes typewriter {
//     to{
//       left: 100%;
//     }        
//   }
//   @keyframes hideTitle {
//     from{
//       opacity: 1;
//     }
    
//     to{
//       opacity: 0;
//     }
//   }

// .titleBox{
//   margin-bottom: 8px;
//   @include fontMulish(40px, #262626, 900, 46px);
//   width: max-content;
//   height: 46px;
// }
@import '../../../../../Theme/theme';

.titleBox{
  height: 54px;
  overflow: hidden;
  margin-bottom: 5px;
  padding: 0;
  width: max-content;
  .titleCursor{
    @include fontMulishRegular(54px, #262626, 900, 45px);
    letter-spacing: 0.01em;
    position: relative;
    .title{
      @include fontMulish(44px, #262626, 900, 45px);
      letter-spacing: 0.01em;
      position: relative;
    }
    // span[class='typed-cursor']{
    //   font-weight: 300 !important;
    //   font-size: 50px;
    // }
    // span[class='typed-cursor--blink']{
    //   font-weight: 300 !important;
    //   font-size: 50px;
    // }
  }
  // li::after{
  //   content: '';
  //   position: absolute;
  //   height: 100%;
  //   width: 100%;
  //   left:0;
  //   background-color: white;
  //   border-left: 4px solid rgba(0, 0, 0, 0.85);
  //   animation: typing 5s steps(30) infinite;
  // }
  // @keyframes typing {
  //   40%,
  //   60%{
  //     left: calc(100%);
  //   }
  //   100%{
  //     left: 0;
  //   }
  // }
  // @keyframes slide{
  //   100%{
  //     top: -270px;
  //   }
  // } 
}