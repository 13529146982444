@import '../../../Theme/theme';

.container {
  position: relative;
  max-width: 670px;
  .avatar {
    position: absolute;
    top: 10px;
    right: 0;
    display: flex;
    align-items: center;
    .tooltipIcon {
      color: $devider-grey;
      position: relative;
      z-index: 1;
    }
  }
  .label {
    white-space: nowrap;
  }
}

.button {
  margin-left: 12px;
  height: 42px;
  border: none;
}

.inputWidth360 {
  max-width: 360px;
}
.inputWidth320 {
  max-width: 320px;
}

.phoneInput {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.helper {
  @include font(12px, $primary-color, $fw-regular);
  cursor: pointer;
  max-width: 120px;
}

.saveButton {
  background: linear-gradient(to right, $primary-light, $blue-light);
  width: 180px;
}

label[title~='Location'] {
  top: 50%;
}

.withOutDot {
  label[for~='work-account_mobile_mechanic']::after {
    visibility: hidden;
  }
}

.withOutDotIsWorkshop {
  label[for~='work-account_is_workshop']::after {
    visibility: hidden;
  }
}
.withOutDotAnyLabel {
  label[title~='none'] {
    visibility: hidden;
  }
}

@media screen and (max-width: 743px) {
  .container {
    .label {
      white-space: pre-wrap;
    }
  }
}

@media screen and (max-width: 700px) {
  .container {
    .avatar {
      position: static;
      margin-bottom: 5px;
      .tooltipIcon {
        position: absolute;
        left: 140px;
      }
    }
    .label {
      white-space: pre-wrap;
    }
  }
}

@media screen and (max-width: 575px) {
  .withOutDot, .withOutDotIsWorkshop {
    & > div:first-child {
      display: none;
    }
  }

  label[title~='Location'] {
    top: 30%;
  }
}

@media screen and (max-width: 400px) {
  .inputWidth320 {
    max-width: 280px;
  }
}

@media screen and (max-width: 360px) {
  .inputWidth320 {
    max-width: 238px;
  }
}