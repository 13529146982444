@import '../../../../Theme/theme';

.contentContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  //margin-left: 101px;
  gap: 60px
}


@media (max-width: 1310px) {
  .contentContainer {
    margin-left: 0px;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .hide {
    display: none;
  }
}

@media (max-width: 835px) {
  .container {
    flex-direction: column;
  }

  .contentContainer {
    flex-wrap: wrap;
  }
}
@media (max-width: 575px) {
  .contentContainer {
    .container {
      margin: 0 16px;
    }
  }
}
