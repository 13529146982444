@import '../../../../Theme/theme';

.active {
  color: red;
}

.container {
  width: 100%;
  //background: url(../../../../Assets/Landing/footer-bacground.png) no-repeat;
  background-position: bottom right;
  background: linear-gradient(275.35deg, #3299C8 0.62%, #026BB5 19.78%, #043F69 99.69%);
  padding-top: 37px;
  padding-bottom: 30px;
  position: relative;

  .contentWrapper {
    width: 100%;
    padding: 0 142px;
    margin: 0 auto;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 40px;

      .leftBlock {
        display: flex;
        gap: 15px;
        div {
          @include font(18px, $color: $white);
          opacity: 0.7;
          padding-top: 10px;
        }
      }
      .rightBlock {
        width: 341px;
        display: flex;
        justify-content: end;
        text-align: end;
        a + a {
          margin-left: 24px;
        }
        p{
          @include font(18px, $white, $fw-regular, 120%);
          opacity: 0.7;
          padding-right: 24px;
          margin: 0;
        }
      }
    }

    .phone {
      @include font(24px, $white, $fw-regular, 32px);
      opacity: 0.7;
      margin-left: auto;
      max-width: 206px;
      text-align: end;
      div:last-child {
        @include font(18px, $white, $fw-regular, 28px);
        margin-top: 2px;
        margin-left: auto;
        letter-spacing: 4px;
      }
    }

    .linkContainer {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      flex-wrap: wrap;
      .leftGroup {
        display: flex;
        gap: 138px;
        flex-wrap: wrap;
        ul {
          padding-left: 0;
        }
        li {
          list-style-type: none;
          @include font(18px, $color: $white, $height: 28px);
          cursor: pointer;
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
        a {
          @include font(18px, $color: $white, $height: 28px);
        }
      }

      .sertification{
        position: absolute;
        bottom: 75px;
      }

      .email {
        @include font(18px, $color: $white, $height: 28px);
        opacity: 0.7;
        cursor: pointer;
        text-align: end;
        div:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
  .copyright {
    @include font(14px, $color: $white);
    opacity: 0.7;
    max-width: 164px;
    margin: 118px auto 0 auto;
  }
  
}
@media screen and (max-width: 1440px) {
  .container {
    .contentWrapper {
      max-width: 1156px;
      padding: 0;
    }
  }
}
@media screen and (max-width: 1190px) {
  .container {
    .contentWrapper {
      padding: 0 16px;
    }
  }
}

@media screen and (max-width: 780px) {
  .container {
    .contentWrapper {
      .header{
        justify-content: center;
        .leftBlock{
          div{
            display: none;
          }
        }
        .rightBlock{
          justify-content: center;
          text-align: center;
          p{
            display: none;
          }
        }
      }
      .phone {
        margin: 32px auto;
        text-align: center;
      }
      .linkContainer {
        justify-content: center;
        .leftGroup {
          gap: 30px;
          ul {
            min-width: 260px;
          }
        }

        .sertification{
          order: -1;
          position: relative;
          width: max-content;
          bottom: 0;
          margin: 32px auto;
        }

        .email {
          width: 100%;
          order: -2;
          margin: 0 auto;
          //margin-bottom: 40px;
          text-align: center;
          .link{
            display: none;
          }
        }
      }
    }
    .copyright {
      margin: 18px auto 0 auto;
    }
  }
}
