@import '../../Theme/theme';

.container {
  min-height: 100vh;
  background-color: white;

  .header img {
    position: absolute;
    left: 46px;
    top: 16px;
  }
}
.bannerImageModels{
  width: 634px;
  height: 487px;
  //margin: auto;

}
.customerBackground {
  margin-top: 70px;
  background: url(../../Assets/banner-add-car.png);
  background-size: contain;
  //background-repeat: no-repeat;
}

.workshopBackground {
  background: url(../../Assets/login-workshop-background.svg);
  background-repeat: no-repeat;
}

.adminBackground {
  background: url(../../Assets/login-admin-background.svg);
}

.backgroundImage {
  display: flex;
  justify-content: space-between;
  // background-position: calc(50% - 270px) center;
  // width: 1110px;
  // height: 487px;
  background-repeat: no-repeat;
  max-width: 1440px;
  margin: 0 auto;
}

.descriptionLeft {
  @include font(24px, $font-color, 32px);
}

.logoForm {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  //margin-top: 70px;
  //background-size: cover;
}

.loginContainer {
  width: 50%;
}

.form p {
  @include font(24px, $font-color, 400, 32px);
  padding: 12%;
  text-align: center;
}

@media (max-width: 1200px) {
  .logoForm {
    display: none;
  }
  .loginContainer {
    width: 100%;
  }
}
