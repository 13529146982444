@import '../../Theme/theme';

.car {
  box-shadow: 0px -7px 10px rgba(55, 78, 94, 0.02),
    0px 6px 20px rgba(55, 78, 94, 0.08);
  padding: 16px;
  border-radius: 6px;
  align-items: center;
  height: 230px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 36px;
  //max-width: 1200px;
  img {
    height: 204px;
    width: 275px;
  }
}
.buttonClass {
  position: relative;
  // right: '-80px',
  top: -75px;
  border: 1px solid #f5222d;
  border-radius: 40px;
  margin: 0px;
  min-width: 20px;
  width: 172px;
}

.description {
  margin-left: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 55%;
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 835px) {
  .car {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .buttonClass {
    position: static;
    // right: '-80px',
    // top: -75px;
  }
  .description {
    margin-left: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75%;
    h3 {
      font-style: normal;
      text-align: center;
      font-weight: 500;
      font-size: 24px;
      line-height: 27px;
      color: rgba(0, 0, 0, 0.85);
    }
  
    p {
      font-style: normal;
      text-align: center;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media (max-width: 835px) {
  .car{
    img{
      width: 200px;
      height: 153px;
    }
  }
  .description {
    gap: 6px 0;
    margin-bottom: 10px;
  }
}