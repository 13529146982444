@import '../../Theme/theme';

.container {
  margin: 16px 0;
  .cardWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    background-color: #026BB5;
    //padding: 16px;
    border-radius: 4px;
    

    .workshopImage {
      max-width: 182px;
      max-height: 182px;
      border-radius: 4px;
      object-fit: cover;
      object-position: center;
      
    }

    .workshopInfoContainer {
      margin: 12px 16px;

      .workshopName{
        @include fontMulishBold(18px, white, 400, 110%);
        margin-bottom: 12px;
      }

      .workshopManager{
        @include fontMulishBold(14px, white, 400, 110%);
        margin-bottom: 12px;
      }

      .link {
        @include font(12px,white, 400, 20px);
        //margin-left: 9px;
        //height: 16px;
        display: flex;
        flex-direction: row;
        
        .google{
          width: 16px;
          height: 16px;
          margin: 0 4px;
          margin-top: 2px;
        }
        .rateBox{
          height: 13.5px;
          margin-right: 8.5px;
          margin-left: 4px;
          .rate{
            display: flex;
            flex-direction: row;
            height: 20px;
            align-items: center;
            //align-items: flex-end;
            li{
              width: 13.5px;
              padding-bottom: 5px;
              margin-right: 1.5px;
              svg{
                height: 13.5px;
                width: 13.5px;
              }
            }
          }
        }
        a{
          text-decoration:none;
        }
      }

      .traidingHoursContainer {
        display: flex;
        .mapContainer {
          display: flex;
          width: 100%;
        }
        .dayWrapp {
          min-width: 39px
        }
        .dayWrapp + .dayWrapp {
            margin-left: 12px;
        }

        .weekDay {
          @include font(12px, white, $fw-regular, 20px);
          //margin-bottom: 4px;
          text-transform: capitalize;
        }
        .workTime {
          @include font(10px, white, $fw-regular, 12px);
          opacity: 0.7;

        }
      }

      .headerHours {
        border-top: 1px solid rgba(255,255,255,0.7);
        @include font(14px,white, 700, 22px);
        margin-bottom: 0;
        margin-top: 36px;
      }
    }
  }

  .descriptionText {
    @include font;
    margin-top: 20px;
    
    .diagram{
      display: flex;
      justify-content: space-between;
      margin-right: 27px;
      margin-left: 33px;
      .quality{
        img{
          width: 139px;
          height: 145px;
        }
      }
      
      .workshopSpec{
        display: flex;
        width: 63%;
        flex-direction: row;
        justify-content: space-between;
        .workshopPoints{
          .partner {
            & > div:first-child {
              padding-bottom: 2px;
            }
            display: flex;
            align-items: center;
            .checkIcon {
              width: 16px;
              height: 16px;
            }
            span {
              display: inline-block;
              align-self: flex-end;
              margin-left: 4px;
              @include font(14px, rgba(0, 0, 0, 0.85), 400, 22px);
            }
          }
        }
        
        .specialist {
          @include font(14px, rgba(0, 0, 0, 0.85), 400, 22px);
            
          .specialistItem {
            margin: 4px 0;
              
            & > img {
              margin-right: 6px;
            }
          }
        }
      }
    }
  }

  .aboutTeam{
    margin-top: 20px;
    @include fontMulishBold(18px, rgba(0, 0, 0, 0.85), 800, 120%);
    margin-bottom: 8px;
  }

  .headline{
    @include font(14px, rgba(0, 0, 0, 0.85), 700, 22px);
    margin-bottom: 8px;
  }

  .reviewsHeader{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    h3{
      @include font(16px, rgba(0, 0, 0, 0.45), 700, 24px);
      margin: 0 16px;
    }
    //width: max-content;
    div{
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      width: 100%;
      height: 15px;
    }
    
  }

  .feedbackContainer {
    height: 150px;
    overflow-y: scroll;
    margin-bottom: 10px;
    .reviews{
      h3{
        @include font(14px, rgba(0, 0, 0, 0.85), 700, 22px);
        margin: 0;
      }
      .rate{
        display: flex;
        flex-direction: row;
        align-items: center;
        h4{
          @include font(14px, rgba(0, 0, 0, 0.45), 400, 22px);
          margin: 0;
          padding-left: 20px;
          padding-top: 2px;
        }
      }
      p{
        @include font(14px, rgba(0, 0, 0, 0.45), 400, 22px);
        padding-right: 5px;
      }
    }
  }
}
@media screen and (max-width: 694px) {
  .cardWrapper{
    justify-content: center !important;
  }
  .container{
    margin: 16px 0;
  }
  .workshopImage {
    width: 160px;
    height: 160px;
    margin-top: 16px;
  }
  .workshopInfoContainer { 
    width: 100%;
    margin: 0 32px;
    text-align: center;
  }
  .link{
    display: flex;
    justify-content: center;
  }
  .traidingHoursContainer {
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .diagram{
    flex-direction: column;
    align-items: center;
    .workshopSpec{
      width: 100% !important ;
      .specialistItem{
        font-size: 12px !important;
      }
      .partner{
        span{
          font-size: 12px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .container{
    padding-left: 0;
    //padding-left: -5%;
    margin-left: -16px;
    margin-right: -16px;
  }
}

@media screen and (max-width: 532px) {
  .diagram{
    margin: 0 !important;
  }
  .traidingHoursContainer {
    overflow-x: scroll;
    justify-content:space-around;
    padding-bottom: 12px;
    .dayWrapp + .dayWrapp {
      margin-left: 6px !important;
    }
  }

  .button{
    margin-right: 30px;
  }

  .feedbackContainer{
    margin: 0 16px 10px 16px;
  }

  .descriptionText{
    padding: 0 16px;
  }
}

@media screen and (max-width: 460px) {
  .workshopInfoContainer { 
    width: 100%;
    margin: 0 32px;
    text-align: left;
  }
  .headerHours{
    margin-top: 12px !important;
    text-align: left;
  }
  .link{
    justify-content: left;
  }
}