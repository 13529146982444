@import '../../../../Theme/theme';

.contentContainer {
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;

  //background: url(../../../../Assets/guest-background.svg);
  background-position: right center;
  background-repeat: no-repeat;
  //max-width: 1440px;
  h1{
    @include font(38px, #003356, 500, 46px);
    margin: 0;
    margin-top: 56px;
  }
}

.buttons {
  width: 95%;
  display: flex;
  justify-content: flex-end;
  //margin-top: 50px;
}
.formWrapper {
  max-width: 330px;
  margin: 32px 0 0 0;
  display: flex;
  gap: 154px;

  .banner{
    width: 634px;
    height: 494px;
  }
}

// .formWrapperGuest {
//   max-width: 330px;
//   margin: 60px 0 0 0;
// }
@media (max-width: 1350px) {
  .formWrapper {
    gap: 54px;
  }
}

@media (max-width: 1150px) {
  .contentContainer {
    //margin: 0;
    background: none;
    h1{
      text-align: center;
    }
  }
  .formWrapper {
    .banner{
      display: none;
    }
  }

  .formWrapperGuest {
    margin: 60px auto;
  }

  .formWrapper {
    margin: 60px auto;
  }
}
